import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg";

export const getBounds = (t) => {
  return [
    [t.longitude - t.width, t.latitude - t.width],
    [t.longitude + t.width, t.latitude + t.width],
  ];
};

export const createMap = (container, task) => {
  return new mapboxgl.Map({
    container: container,
    style: "mapbox://styles/mapbox/satellite-v9", // style URL
    interactive: true, // Needed for mapbox-gl-draw styling
    bounds: task.latitude ? getBounds(task) : null,
    maxBounds: task.latitude ? getBounds(task) : null,
  });
};

export const showTask = (map, task) => {
  const bounds = getBounds(task);
  // Allow map to show new point
  map.setMaxBounds(bounds);
  // Move the map to the central point
  map.fitBounds(bounds, { duration: 0 });
  // Restrict map to current viewport
  map.setMaxBounds(map.getBounds());
};

export const categories = [
  // { name: "unknown", label: "Unknown", color: null },
  { name: "built", label: "Built", color: "#ef4444" },
  { name: "water", label: "Water", color: "#3b82f6" },
  { name: "trees", label: "Trees", color: "#22c55e" },
  { name: "crop", label: "Crop", color: "#FFC610" },
  { name: "Bare", label: "Bare", color: "#C5BAAB" },
];

export const colorToName = Object.fromEntries(
  Object.entries(categories).map(([_, c]) => [c.color, c.name]),
);
export const colorToIndex = Object.fromEntries(
  Object.entries(categories).map(([i, c]) => [c.color, i]),
);
export const nameToColor = Object.fromEntries(
  Object.entries(categories).map(([_, c]) => [c.name, c.color]),
);

export function extractLabels(map, features) {
  return features.map((f) => {
    const fs = map.current.getFeatureState({
      source: "grid",
      id: f.id,
    });
    return { feature: f, label: colorToName[fs.color || null] };
  });
}

export const mapboxDrawStyle = [
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "#3bb2d0",
      "fill-outline-color": "#3bb2d0",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#fbb03b",
      "fill-outline-color": "#fbb03b",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000", //"#3bb2d0",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000", //"#3bb2d0",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-opacity": 1,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#3bb2d0",
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-fill-static",
    type: "fill",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#404040",
      "fill-outline-color": "#404040",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-stroke-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-static",
    type: "line",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#404040",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-static",
    type: "circle",
    filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 5,
      "circle-color": "#404040",
    },
  },

  // end default themes provided by MB Draw
  // end default themes provided by MB Draw
  // end default themes provided by MB Draw
  // end default themes provided by MB Draw

  // new styles for toggling colors
  // new styles for toggling colors
  // new styles for toggling colors
  // new styles for toggling colors

  {
    id: "gl-draw-polygon-color-picker",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["has", "user_portColor"]],
    paint: {
      "fill-color": ["get", "user_portColor"],
      "fill-outline-color": ["get", "user_portColor"],
      "fill-opacity": 0.5,
    },
  },
  {
    id: "gl-draw-line-color-picker",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["has", "user_portColor"]],
    paint: {
      "line-color": ["get", "user_portColor"],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-color-picker",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["has", "user_portColor"]],
    paint: {
      "circle-radius": 3,
      "circle-color": ["get", "user_portColor"],
    },
  },
];

const sentinel2SourceLayer = "sentinel-2-tiles";
const sentinel2TileLayer = sentinel2SourceLayer + "-layer";

export function addSentinel2Tiles(map, sceneId) {
  const tileUrl = `https://gta-titiler-us-west-2-lambda-dev.groundtruthanalytics.com/scenes/sentinel2_l2a_cog/tiles/WebMercatorQuad/{z}/{x}/{y}@2x?url=${sceneId}&bands=B4&bands=B3&bands=B2&rescale=0%2C3558&resampling=bilinear&padding=1`;
  if (map.getSource(sentinel2SourceLayer)) {
    map.getSource(sentinel2SourceLayer).tiles = [tileUrl];
    map.style._sourceCaches["other:" + sentinel2SourceLayer].clearTiles();
    map.style._sourceCaches["other:" + sentinel2SourceLayer].update(
      map.transform,
    );
    map.triggerRepaint();
  } else {
    map.addSource(sentinel2SourceLayer, {
      type: "raster",
      tiles: [tileUrl],
    });
    map.addLayer(
      {
        id: sentinel2TileLayer,
        type: "raster",
        source: sentinel2SourceLayer,
        minzoom: 0,
        maxzoom: 22,
        paint: {
          "raster-opacity": 1,
        },
      },
      "after-satellite",
    );
  }
}

export function setSentinel2Visibility(map, visible) {
  if (map.getLayer(sentinel2TileLayer)) {
    if (visible) {
      map.setLayoutProperty(sentinel2TileLayer, "visibility", "visible");
    } else {
      map.setLayoutProperty(sentinel2TileLayer, "visibility", "none");
    }
  }
}
