import { Form, Formik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { post, unwrapError } from "../../common/utils/api";
import EmailInput from "../components/EmailInput.js";

function ResetPassword({ showAlert, t }) {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState({});

  function onSubmit(value, { setSubmitting }) {
    setErrorData({});
    post("/users/password_reset/", {
      email: value.email,
    })
      .then(function () {
        setErrorData({});
        showAlert({ type: "success", message: "Password reset email sent" });
        navigate("/");
      })
      .catch(function (error) {
        setErrorData(unwrapError(error, true));
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  }

  return (
    <div>
      <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
        {t("user.resetPassword", "Reset password")}
      </div>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className="mt-6 mx-auto w-full max-w-sm">
            <p className="mb-6 text-center text-xs font-medium text-red-600">
              {errorData.non_field_errors || "\u00A0"}
            </p>
            <div className="mb-2">
              <EmailInput errorData={errorData} />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-700 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              <span>{t("user.reset", "Reset")}</span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation()(ResetPassword);
