import { Navigate } from "react-router-dom";

import { useUser } from "../utils/user";

export default function CheckPermissions({
  children,
  openAccess,
  role,
  showAlert,
}) {
  const { user } = useUser();

  // If page is open access, don't enforce any checks
  if (!openAccess) {
    // Redirect to login page if user is not logged in
    if (!user) {
      return <Navigate to="/users/login" replace />;
    }

    // Enforce any role restriction
    if (role) {
      if (!user.user.roles?.includes(role)) {
        // We can't update the state of the alert while the page is rendering,
        // so set it to run on a timeout instead
        setTimeout(
          () => showAlert({ type: "error", message: "Permission denied" }),
          0,
        );
        return <Navigate to="/" replace />;
      }
    }
  }

  return children;
}
