import { Form, Formik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Select from "../../common/components/form/Select.js";
import { put, unwrapError } from "../../common/utils/api";
import { setLanguage } from "../../common/utils/helpers";
import FirstNameInput from "../components/FirstNameInput.js";
import LastNameInput from "../components/LastNameInput.js";
import PasswordInput from "../components/PasswordInput.js";
import { useUser } from "../utils/user";

function User({ showAlert, t }) {
  const navigate = useNavigate();
  const { user, refresh } = useUser();

  const [errorData, setErrorData] = useState({});

  function onSubmit(value, { setSubmitting }) {
    setErrorData({});

    setLanguage(value.language);

    put("/users/current/", { ...value })
      .then(function (response) {
        setErrorData({});
        showAlert({
          type: "success",
          message: t("user.changeSuccessful", "Change successful"),
        });
        refresh(response.data).then(() => navigate("/"));
      })
      .catch(function (error) {
        setErrorData(unwrapError(error, true));
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  }

  return (
    <div>
      <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
        {t("user.updateUserInformation", "Update user information")}
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: user.user.first_name,
          last_name: user.user.last_name,
          password: "",
          password2: "",
          language: user.user.language,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="mt-6 mx-auto w-full max-w-sm">
            <p className="mb-6 text-center text-xs font-medium text-red-600">
              {errorData.non_field_errors || "\u00A0"}
            </p>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                {t("user.emailAddress", "Email address")}
              </label>
              <input
                type="email"
                data-testid="email"
                disabled
                value={user.user.email}
                className="mt-1 appearance-none text-gray-500 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-cyan-400 focus:ring-2 focus:ring-sky-500 border-gray-300"
              />
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="mb-2">
                <FirstNameInput errorData={errorData} />
              </div>
              <div className="mb-2">
                <LastNameInput errorData={errorData} />
              </div>
              <div className="mb-2">
                <PasswordInput errorData={errorData} isConfirm={false} />
              </div>
              <div className="mb-2">
                <PasswordInput errorData={errorData} isConfirm={true} />
              </div>
            </div>
            <div className="mb-6">
              <Select
                label={"Language"}
                name={"language"}
                options={{ en: "English", fr: "French" }}
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-700 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              <span>{t("user.update", "Update")}</span>
            </button>

            <Link
              to="/"
              className={`mt-1 inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-gray-300 text-gray-700 w-full ${
                isSubmitting
                  ? "bg-gray-100 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              {t("common.cancel", "Cancel")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation()(User);
