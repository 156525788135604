import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import Select from "../../common/components/form/Select.js";
import TextInput from "../../common/components/form/TextInput.js";
import {
  MIN_ACCURACY,
  MIN_DISTANCE,
  clearWatch,
  getDistance,
  parseManualLocation,
  watchPosition,
} from "../utils/location";
import Crop from "./Crop.js";

function Spinner() {
  return (
    <div role="status" className="inline-block">
      <svg
        aria-hidden="true"
        className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-cyan-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
}

export function IrrigationInput({ t }) {
  const irrOptions = {
    notIrrigated: t("crop.notIrrigated", "No"),
    Irrigated: t("crop.irrigated", "Yes"),
  };

  function validateRequire(value) {
    let error;
    if (!value) {
      error = t("crop.requiredField", "Required field");
    }
    return error;
  }

  return (
    <Select
      label={t("crop.irrigationStatus", "Is there an irrigation system?")}
      name={"irrigation"}
      options={irrOptions}
      validate={validateRequire}
      required={true}
      showLegend={false}
    />
  );
}

function Plot({ showAlert, t, previousLocationData, setOpen, setPopup }) {
  const {
    values: { longitude, latitude, accuracy, allowLowAccuracy, distance },
    setFieldValue,
    setFieldTouched,
    errors,
  } = useFormikContext();
  const [updatingLocation, setUpdatingLocation] = useState(false);
  const [color, setColor] = useState("gray");
  const currentLocationRef = useRef(null);

  function validateRequire(value) {
    let error;
    if (!value) {
      error = t("crop.requiredField", "Required field");
    }
    return error;
  }

  function validateDistance(distance) {
    if (distance !== "" && distance !== "-" && distance < MIN_DISTANCE) {
      return t("plot.locationTooClose", "Too close to previous data");
    }
    return null;
  }

  useEffect(() => {
    // Only autopopulate the position when it's empty (e.g., when user edits
    // a form, it will not be updated)
    if (!longitude && !latitude) {
      startWatchPosition();
    }
  }, []);

  useEffect(() => {
    if (accuracy && accuracy > MIN_ACCURACY) {
      setColor("red");
    } else {
      setColor("gray");
    }
  }, [accuracy]);

  // Reset location button when low accuracy mode is toggled
  useEffect(() => {
    if (allowLowAccuracy !== undefined) {
      setUpdatingLocation(false);
    }
  }, [allowLowAccuracy]);

  useEffect(() => {
    runWatchPositionUntilSave();
  }, []);

  function runWatchPositionUntilSave() {
    watchPosition(
      showAlert,
      t,
      (position) => {
        const currentLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        };
        currentLocationRef.current = currentLocation;
      },
      () => {},
      {
        enableHighAccuracy: !allowLowAccuracy,
      },
    );
  }

  function startWatchPosition() {
    const id = watchPosition(
      showAlert,
      t,
      (position) => {
        setFieldValue("longitude", position.coords.longitude);
        setFieldValue("latitude", position.coords.latitude);
        setFieldValue("accuracy", position.coords.accuracy);
        setFieldValue("locationManual", "");
        setFieldValue(
          "distance",
          getDistance(previousLocationData, {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        );
        setFieldTouched("distance");
      },
      () => {
        setUpdatingLocation(false);
      },
      {
        enableHighAccuracy: !allowLowAccuracy,
      },
    );
    setUpdatingLocation(id);
  }

  function stopWatchPosition() {
    clearWatch(updatingLocation);
    setUpdatingLocation(false);
  }

  const distColor = errors.distance ? "red" : "gray";

  const isUpdatingLocation = updatingLocation !== false;

  return (
    <React.Fragment>
      <div className="space-y-6 sm:space-y-5 pt-4 sm:pt-6 lg:pt-8">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("plot.plotInformation", "Plot Information")}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t("plot.informationAboutThisPlot", "Information about this plot")}
          </p>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <TextInput
            label={t("plot.giveThePlotAName", "Give the plot a name")}
            name="name"
          />

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {t("plot.location", "Location")}
              <span className="text-red-800 text-superscript">*</span>
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="block w-full max-w-lg sm:max-w-xs sm:text-sm">
                <div className="grid grid-cols-2">
                  <div className="relative">
                    <span className="absolute pt-2 text-gray-700 font-medium w-full text-center">
                      Latitude
                    </span>
                    <Field
                      type="text"
                      name="latitude"
                      id="latitude"
                      data-testid="latitude"
                      validate={validateRequire}
                      disabled
                      className="rounded-tl-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm text-gray-500 pt-7 w-full text-center text-ellipsis border-r-0"
                    />
                  </div>
                  <div className="relative">
                    <span className="absolute pt-2 text-gray-700 font-medium w-full text-center">
                      Longitude
                    </span>
                    <Field
                      type="text"
                      name="longitude"
                      id="longitude"
                      data-testid="longitude"
                      validate={validateRequire}
                      disabled
                      className="rounded-tr-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm text-gray-500 pt-7 w-full text-center text-ellipsis"
                    />
                  </div>
                  <div className="relative">
                    <span
                      className={`absolute pt-2 text-${color}-700 font-medium w-full text-center`}
                    >
                      {t("plot.accuracy", "Accuracy (m)")}
                    </span>
                    <Field
                      type="text"
                      name="accuracy"
                      id="accuracy"
                      data-testid="accuracy"
                      validate={validateRequire}
                      disabled
                      className={
                        `border-gray-300 border-t-0 border-b-0 border-r-0 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm text-${color}-500 pt-7 w-full text-center text-ellipsis` +
                        (color === "gray" ? "" : ` bg-${color}-50`)
                      }
                    />
                  </div>
                  <div className="relative">
                    <span
                      className={`absolute pt-2 text-${distColor}-700 font-medium w-full text-center`}
                    >
                      {t("plot.distance", "Distance (m)")}
                    </span>
                    <Field
                      type="text"
                      name="distance"
                      id="distance"
                      data-testid="distance"
                      validate={validateDistance}
                      disabled
                      className={
                        `border-gray-300 border-t-0 border-b-0 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm text-${distColor}-500 pt-7 w-full text-center text-ellipsis` +
                        (distColor === "gray" ? "" : ` bg-${distColor}-50`)
                      }
                    />
                  </div>
                </div>

                <button
                  type="button"
                  onClick={
                    isUpdatingLocation ? stopWatchPosition : startWatchPosition
                  }
                  className={`inline-flex items-center rounded-b-md border border-transparent ${
                    isUpdatingLocation
                      ? "bg-yellow-500 hover:bg-yellow-600"
                      : "bg-cyan-600 hover:bg-cyan-700"
                  } px-4 py-2 text-sm font-medium text-white focus:outline-none shadow-sm w-full justify-center`}
                >
                  {isUpdatingLocation ? (
                    <div className="items-center flex">
                      <Spinner />
                      {t("plot.stopTrackingLocation", "Stop tracking location")}
                    </div>
                  ) : (
                    t("plot.startTrackingLocation", "Start tracking location")
                  )}
                </button>
                <p className={`text-sm font-medium text-red-800 mt-0`}>
                  <ErrorMessage name={"latitude"} />
                </p>
                <p className={`text-sm font-medium text-red-800 mt-0`}>
                  <ErrorMessage name={"distance"} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Crop currentLocationRef={currentLocationRef} />
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("plot.additionalInformation", "Additional Information")}
          </h3>
        </div>
        <IrrigationInput t={t} />
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Plot);
