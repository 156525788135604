import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-4 px-4">
        <div className="mt-12 pt-4">
          <div className="grid grid-cols-3 text-sm text-gray-400 ">
            <p></p>
            <p className="text-center">
              &copy; 2022 Ground Truth Analytics LLC
            </p>
            <Link to="/debug">
              <p className="text-right text-gray-300">Advanced</p>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
