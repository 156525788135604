import { isCorn, isGrain } from "./Yield.js";

function CropDetails({ crop, crop_index }) {
  return (
    <>
      <span>Yield</span>
      <ul className="list-disc list-inside text-xs">
        <li data-testid={`${crop_index}-cropSize`}>Size: {crop.yield.size}</li>

        {isGrain(crop.crop) ? (
          <>
            {isCorn(crop.crop) && (
              <>
                <li data-testid={`${crop_index}-nPlants`}>
                  Number of plants: {crop.yield.n_plants}
                </li>
                <li data-testid={`${crop_index}-nCobs`}>
                  Number of cobs: {crop.yield.n_cobs}
                </li>
              </>
            )}
            {crop.yield.using_moisture === "yes" && (
              <>
                <li data-testid={`${crop_index}-yield`}>
                  Yield: {crop.yield.yield}
                </li>
                <li data-testid={`${crop_index}-moisture`}>
                  Moisture: {crop.yield.moisture}
                </li>
              </>
            )}
            {crop.yield.using_moisture === "no" && (
              <>
                <li data-testid={`${crop_index}-yield_w1`}>
                  W1: {crop.yield.yield_w1}
                </li>
                {isCorn(crop.crop) && (
                  <li data-testid={`${crop_index}-yield_w2`}>
                    W2: {crop.yield.yield_w2}
                  </li>
                )}
                <li data-testid={`${crop_index}-yield_w3`}>
                  W3: {crop.yield.yield_w3}
                </li>
                <li data-testid={`${crop_index}-yield_w4`}>
                  W4: {crop.yield.yield_w4}
                </li>
                <li data-testid={`${crop_index}-yield_w5`}>
                  W5: {crop.yield.yield_w5}
                </li>
              </>
            )}
          </>
        ) : (
          <>
            <li>Yield: {crop.yield.yield}</li>
          </>
        )}
      </ul>
    </>
  );
}
export default CropDetails;
