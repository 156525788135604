import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { useUser } from "./user/utils/user";

import { RegisterServiceWorker } from "./RegisterServiceWorker";
import Alert from "./common/components/Alert";
import Footer from "./common/components/Footer";
import Header from "./common/components/Header";
import InstallPrompt from "./common/components/InstallPrompt";
import commonRoutes from "./common/routes";
import { setLanguage } from "./common/utils/helpers";
import dataRoutes from "./data/routes";
import delineation2Routes from "./delineation2/routes";
import delineationRoutes from "./delineation/routes";
import "./i18n";
import landcover2Routes from "./landcover2/routes";
import landcoverRoutes from "./landcover/routes";
import CheckPermissions from "./user/components/CheckPermissions";
import userRoutes from "./user/routes";

function prefixRoutes(prefix, routes, role) {
  return routes.map((r) => {
    return { ...r, path: prefix + "/" + r.path, role };
  });
}

const routes = [
  ...prefixRoutes("/", commonRoutes),
  ...prefixRoutes("/users", userRoutes),
  ...prefixRoutes("/data", dataRoutes, "Data"),
  ...prefixRoutes("/delineation", delineationRoutes, "Delineation"),
  ...prefixRoutes("/delineation2", delineation2Routes, "Delineation2"),
  ...prefixRoutes("/landcover", landcoverRoutes, "Landcover"),
  ...prefixRoutes("/landcover2", landcover2Routes, "Landcover2"),
];

export default function App() {
  const [alert, setAlert] = useState({ show: false });
  const showAlert = (alert) => setAlert({ ...alert, show: true });
  const { user } = useUser();

  useEffect(() => {
    var language = user?.user?.language || window.localStorage.defaultLanguage;
    setLanguage(language);
  }, [user]);

  return (
    <div className="flex flex-col h-screen w-full">
      <Header />
      <div className="w-full px-4 sm:px-6 lg:px-8 absolute mt-2">
        <div className="mx-auto table">
          <Alert {...{ ...alert, setAlert: setAlert }} />
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8 mt-8 sm:mt-12 lg:mt-16 mb-auto">
        <Routes>
          {routes.map((r) => (
            <Route
              exact
              key={r.path}
              path={r.path}
              element={
                <CheckPermissions
                  openAccess={r.openAccess}
                  role={r.role}
                  showAlert={showAlert}
                >
                  <r.element showAlert={showAlert} />
                </CheckPermissions>
              }
            />
          ))}
        </Routes>
      </div>
      <Footer />
      <InstallPrompt />
      <RegisterServiceWorker />
    </div>
  );
}
