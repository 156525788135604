import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Circle,
  CircleMarker,
  MapContainer,
  Marker,
  ScaleControl,
  useMap,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import Image from "../../common/components/Image";
import { get } from "../../common/utils/api";
import { getImageUrl } from "../../common/utils/helpers";
import { MIN_ACCURACY } from "../utils/location.js";
import { questionCodeMapper } from "./ReviewDetail";

export const L = require("leaflet");
export const blueIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const API_KEY = "AIzaSyCddB8v9ft_ATozIdkN23KBI4SPn-tyuBM";
function FocusMapOnMarkers({ markers, current }) {
  const map = useMap();

  useEffect(() => {
    if (markers && markers.length > 0) {
      let markerBounds = latLngBounds([]);
      markers.forEach((marker) => {
        const { latitude, longitude } = marker.satellite;
        if (latitude && longitude) {
          markerBounds.extend([latitude, longitude]);
        }
      });

      if (markerBounds.isValid()) {
        map.fitBounds(markerBounds);
      }
    }
  }, [markers, map]);

  useEffect(() => {
    if (markers && markers.length > 0) {
      const { latitude, longitude } = markers[current].satellite;
      if (latitude && longitude) {
        map.setView([latitude, longitude], map.getZoom(), { animate: true });
      }
    }
  }, [markers, current, map]);

  return null;
}

function MapDisplay({ data, current, allData }) {
  return (
    <div className="relative w-full h-64" data-testid="map-display">
      <MapContainer
        className="border border-gray-200"
        center={[data.latitude, data.longitude]}
        style={{
          height: "100%",
          width: "100%",
        }}
        zoom={17}
        scrollWheelZoom={false}
        dragging={false}
        zoomControl={false}
        doubleClickZoom={false}
      >
        <FocusMapOnMarkers markers={allData} current={current} />
        <ReactLeafletGoogleLayer
          apiKey={API_KEY}
          type={"satellite"}
          minZoom={3}
          maxZoom={23}
        />
        <Circle center={[data.latitude, data.longitude]} radius={10}>
          <Marker
            position={[data.latitude, data.longitude]}
            icon={blueIcon}
            autoPan={false}
          />
          <CircleMarker
            center={[data.latitude, data.longitude]}
            color={"gray"}
            fillOpacity={0}
            radius={1}
          />
        </Circle>
        <ScaleControl />
      </MapContainer>
    </div>
  );
}

const ImageDisplay = ({ index, field, label, imageUrl, onClick }) => {
  const imageUrls = Array.isArray(imageUrl) ? imageUrl.map(getImageUrl) : [];
  return (
    <div className="flex overflow-x-auto">
      {imageUrls.map((url, idx) => (
        <div
          className="relative w-full h-full snap-start"
          key={`${index}-${idx}`}
        >
          {label && (
            <div className="m-2 absolute inline-flex items-center rounded-md border border-transparent bg-gray-900 opacity-80 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm">
              {label}
            </div>
          )}
          <button
            className="h-60 mt-2 ml-3 w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 shadow-inner"
            onClick={() => onClick(url)}
            style={{
              backgroundImage: `url(${url})`,
              height: "300px",
              width: "300px",
            }}
          >
            <img
              data-testid={`${field}-${idx}`}
              src={url || "No image available"}
              alt={`${field}-${idx}`}
              className="w-full aspect-square hidden"
            />
          </button>
        </div>
      ))}
    </div>
  );
};

const Pagination = ({ current, total, onPrevious, onNext, isSubmitting }) => {
  const isEnd = current === total - 1;

  return (
    <nav
      className="isolate inline-flex space-x-2 sm:space-x-4 rounded-md"
      aria-label="Pagination"
    >
      <button
        type="button"
        onClick={onPrevious}
        disabled={current === 0 || isSubmitting}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </button>
      <div className="flex justify-center">
        <div
          className={`relative inline-flex items-center border border-gray-300 rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:z-20 ${
            isSubmitting && "opacity-50"
          }`}
        >
          <span className="sm:hidden">#</span>
          <span className="hidden sm:inline-block">Record</span>
          &nbsp;{current + 1} / {total}
        </div>
      </div>
      <button
        type="button"
        onClick={onNext}
        disabled={isEnd || isSubmitting}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
      >
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </button>
    </nav>
  );
};

const UserInfo = ({ user, email, questionCode }) => (
  <div className="ml-4 mt-2 space-y-2">
    <div className="pb-2">
      <h1 className="text-2xl font-bold text-gray-900">
        Incorrect Review Results
      </h1>
    </div>
    <div className="flex">
      <UserIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-sm text-gray-500" data-testid="userName">
        {user}
      </span>
    </div>
    <div className="flex">
      <EnvelopeIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-sm text-gray-500" data-testid="email">
        {email}
      </span>
    </div>
    <div className="flex">
      <span className="text-sm bold text-red-600 mb-4">
        <QuestionMarkCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0" />
      </span>
      <span
        className="text-sm text-red-600 mb-4"
        data-testid={`question-code-${questionCode}`}
      >
        {questionCodeMapper[questionCode]}
      </span>
    </div>
  </div>
);

function renderAnswer(answer) {
  return (
    <div data-testid={`answer`} className="text-center">
      Your answer ({answer.answer_code === 0 ? "Yes" : "No"}) was incorrect
    </div>
  );
}

const questionCodeToSectionMapping = {
  Satellite: [1, 2],
  Irrigation: [14],
  Crop: [3, 4, 5, 6, 7, 8, 14],
  Disease: [9],
  Yield: [10, 11, 12, 13],
};

const RenderSection = ({ title, content, images, extraContent, onClick }) => (
  <div data-testid={`${title}-section`}>
    {content && (
      <p>
        {title}: {content}
      </p>
    )}
    {extraContent}
    {Array.isArray(images) &&
      images.length > 0 &&
      images.map(
        (img, idx) =>
          img.imageUrl && (
            <ImageDisplay
              key={idx}
              field={img.key}
              label={img.label}
              imageUrl={img.imageUrl}
              onClick={onClick}
            />
          ),
      )}
  </div>
);

const CropDataComponent = ({
  dataIndex,
  questionCode,
  data,
  questionCodeToSectionMapping,
}) => {
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  function onClick(e) {
    setUrl(e);
    setOpen(true);
  }
  function getYieldImages(yieldData) {
    const yield_images = [
      ["size_images", "YIELD - PEGGED"],
      ["diagonal_images", "YIELD - DIAGONAL"],
      ["harvested_images", "YIELD - HARVESTED"],
      ["yield_images", "YIELD - MEASUREMENT"],
      ["moisture_images", "YIELD - MOISTURE"],
      ["yield_w1_images", "YIELD - W1 - BAG"],
      ["yield_w2_images", "YIELD - W2 - BAG + DEHUSKED"],
      ["yield_w3_images", "YIELD - W3 - BAG + GRAIN"],
      ["yield_w4_images", "YIELD - W4 - SAMPLE"],
      ["yield_w4_drying_images", "YIELD - DRYING"],
      ["yield_w5_images", "YIELD - W5 - AFTER DRYING"],
    ];

    return yield_images.reduce(
      (acc, [key, label]) =>
        acc.concat(
          Array.isArray(yieldData?.[key])
            ? [{ key, label, imageUrl: yieldData[key] }]
            : [],
        ),
      [],
    );
  }

  const sections = [
    {
      key: "Crop",
      condition: questionCodeToSectionMapping.Crop.includes(
        parseInt(questionCode),
      ),
      title: "Crop",
      content: data.crop,
      images: Array.isArray(data?.images)
        ? [{ key: "crop_images", label: `Crop Images`, imageUrl: data?.images }]
        : [],
      extraContent: (
        <>
          {data?.phenology && <p>Phenology: {data?.phenology}</p>}
          {data?.otherCropType && <p>Other Crop Type: {data?.otherCropType}</p>}
          {data?.preparedCropType && (
            <p>Prepared Crop Type: {data?.preparedCropType}</p>
          )}
        </>
      ),
    },
    {
      key: "Disease",
      condition: questionCodeToSectionMapping.Disease.includes(
        parseInt(questionCode),
      ),
      title: "Disease",
      content: data.disease,
      images: Array.isArray(data.disease_images)
        ? [
            {
              key: "disease_images",
              label: `Disease Images`,
              imageUrl: data?.disease_images,
            },
          ]
        : [],
      extraContent: data.comment && <p>Comment: {data.comment}</p>,
    },
    {
      key: "Yield",
      condition: questionCodeToSectionMapping.Yield.includes(
        parseInt(questionCode),
      ),
      title: "Yield",
      content: "",
      images: getYieldImages(data?.yield),
      extraContent: (
        <>
          {data?.yield.size && <p>Size: {data?.yield.size}</p>}
          {data?.yield.diagonal && <p>Diagonal: {data?.yield.diagonal}</p>}
          {data?.yield.n_plants && (
            <p>Number of Plants: {data?.yield.n_plants}</p>
          )}
          {data?.yield.n_cobs && <p>Number of Cobs: {data?.yield.n_cobs}</p>}
          {data?.yield.using_moisture && (
            <p>Using Moisture: {data?.yield.using_moisture}</p>
          )}
          {data?.yield.yield && <p>Yield: {data?.yield.yield}</p>}
          {data?.yield.yield_w1 && <p>Yield W1: {data?.yield.yield_w1}</p>}
          {data?.yield.yield_w2 && <p>Yield W2: {data?.yield.yield_w2}</p>}
          {data?.yield.yield_w3 && <p>Yield W3: {data?.yield.yield_w3}</p>}
          {data?.yield.yield_w4 && <p>Yield W4: {data?.yield.yield_w4}</p>}
          {data?.yield.yield_w5 && <p>Yield W5: {data?.yield.yield_w5}</p>}
        </>
      ),
    },
  ];

  return (
    <div key={dataIndex}>
      <Image url={url} open={open} setOpen={setOpen} />
      {sections.map(
        (section) =>
          section.condition && (
            <div key={section.key}>
              <RenderSection
                title={section.title}
                content={section.content}
                images={section.images}
                extraContent={section.extraContent}
                onClick={onClick}
              />
            </div>
          ),
      )}
    </div>
  );
};

function AnswerDetail({ showAlert, t }) {
  const { user } = useUser();
  let [allData, setAllData] = useState([]);
  let [current, setCurrent] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (allData && allData.length > 0 && current < allData.length) {
      setCurrentData(allData[current]);
    } else {
      setCurrentData(null); // or some default value
    }
  }, [allData, current]);

  const [searchParams] = useSearchParams();
  const userSelected = searchParams.get("user");
  const questionCode = searchParams.get("question_code");
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");

  const getIncorrectData = useCallback(async () => {
    get(
      `/data/review/answer?username=${userSelected}&question_code=${questionCode}&start_date=${startDate}&end_date=${endDate}`,
    ).then((response) => {
      const data = response.data
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        .map((d) => ({
          id: d.id,
          answer: {
            answer_code: d.answer_code,
            review_complete: d.review_complete,
            question_code: d.question_code,
            updated_at: d.updated_at,
            is_approved: d.is_approved,
          },
          satellite: {
            latitude: d.data.latitude,
            longitude: d.data.longitude,
            accuracy: d.data.accuracy || 0,
            manualLocation: d.data.manualLocation,
            lowAccuracy:
              d.data.manualLocation || d.data.accuracy > MIN_ACCURACY,
          },
          crops: d.data.crops,
          irrigation: d.data.irrigation,
        }));

      setAllData(data);
      setCurrentData(data[current]); // Ensure currentData state is immediately updated.
    });
  }, [userSelected, questionCode, startDate, endDate]);

  useEffect(() => {
    getIncorrectData();
    setCurrent(0);
  }, [getIncorrectData]);

  const isEnd = current === allData.length - 1;
  function previous() {
    if (current !== 0) {
      setCurrent(current - 1);
    }
  }
  function next() {
    if (!isEnd) {
      setCurrent(current + 1);
    }
  }

  const getCropIndex = (code) => Math.floor(code / 1000);

  const cropIndex = getCropIndex(currentData?.answer?.question_code);
  const data = currentData?.crops[cropIndex];
  return user ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-start md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-start">
            <UserInfo
              user={currentData?.user}
              email={userSelected}
              questionCode={questionCode}
            />
          </div>
        </div>
        {currentData && (
          <React.Fragment>
            <div className="mt-4 flex flex-wrap items-center justify-center px-4 sm:px-0">
              <Pagination
                current={current}
                total={allData.length}
                onPrevious={previous}
                onNext={next}
                isSubmitting={isSubmitting}
              />
            </div>
            <div className="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
              <header className="md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
                <div className="mt-4 relative w-full h-full">
                  <div className="mb-4">
                    {currentData?.answer &&
                      renderAnswer(currentData?.answer, cropIndex)}
                  </div>
                  <div>
                    {questionCodeToSectionMapping.Irrigation.includes(
                      parseInt(questionCode),
                    ) && (
                      <div data-testid="Irrigation-section">
                        Irrigation: {currentData.irrigation}
                      </div>
                    )}
                    {questionCodeToSectionMapping.Satellite.includes(
                      parseInt(questionCode),
                    ) && (
                      <div data-testid="Satellite-section">
                        Satellite:
                        <div className="relative w-full h-128 mt-2">
                          <MapDisplay
                            data={currentData.satellite}
                            current={current}
                            allData={allData}
                          />
                        </div>
                        <p className="mt-2">
                          Accuracy: {currentData.satellite.accuracy} m
                        </p>
                      </div>
                    )}
                    <div>
                      <div key={cropIndex}>
                        <CropDataComponent
                          dataIndex={cropIndex}
                          questionCode={questionCode}
                          data={data}
                          questionCodeToSectionMapping={
                            questionCodeToSectionMapping
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  ) : null;
}

export default withTranslation()(AnswerDetail);
