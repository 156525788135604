import { useState } from "react";

import { categories } from "../utils/map.js";

export default function Palette({ categoryRef, interactive }) {
  const [category, setCategory] = useState(interactive ? 0 : -1);

  if (interactive) {
    categoryRef.current = category;
  }

  return (
    <div className="mt-8">
      <h3
        className="px-3 text-sm font-medium text-gray-500"
        id="desktop-teams-headline"
      >
        {interactive ? "Select a category" : "Legend"}
      </h3>
      <div
        className="mt-1 space-y-1"
        role="group"
        aria-labelledby="desktop-teams-headline"
      >
        {categories.map((c, i) => {
          return (
            <span
              key={c.name}
              onClick={() => interactive && setCategory(i)}
              className={`group flex items-center rounded-md px-3 py-1 text-sm font-medium text-gray-700 ${
                parseInt(categoryRef.current) === i
                  ? "bg-gray-200 font-bold"
                  : interactive
                    ? "hover:bg-gray-50 hover:text-gray-900"
                    : ""
              } ${interactive ? "cursor-pointer" : ""}`}
            >
              <span
                className="mr-4 h-2.5 w-2.5 rounded-full"
                style={{ backgroundColor: c.color }}
                aria-hidden="true"
              ></span>
              <span className="truncate">{c.label}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
