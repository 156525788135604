import "leaflet/dist/leaflet.css";
import React, { useEffect } from "react";
import { Marker, Tooltip, useMap } from "react-leaflet";

import { getImageUrl } from "../../common/utils/helpers";
import { violetIcon } from "../pages/ReviewDetail";

const L = require("leaflet");

function extractImageGeolocations(data) {
  const geolocations = [];
  if (data?.crops) {
    data.crops.forEach((crop, cropIndex) => {
      ["images", "boundary_images", "disease_images"].forEach((imageType) => {
        if (crop[imageType]) {
          crop[imageType].forEach((img, imgIndex) => {
            if (img.geolocation) {
              geolocations.push({
                timestamp: img.timestamp,
                image: img.image,
                ...img.geolocation,
                label: `${imageType} ${imgIndex + 1} (Crop ${cropIndex + 1}: ${
                  crop.crop
                })`,
              });
            }
          });
        }
      });
      // Add yield-related images
      if (crop.yield) {
        [
          "size_images",
          "diagonal_images",
          "harvested_images",
          "yield_images",
          "moisture_images",
          "yield_w1_images",
          "yield_w2_images",
          "yield_w3_images",
          "yield_w4_images",
          "yield_w4_drying_images",
          "yield_w5_images",
        ].forEach((imageType) => {
          if (crop.yield[imageType]) {
            crop.yield[imageType].forEach((img, imgIndex) => {
              if (img.geolocation) {
                geolocations.push({
                  timestamp: img.timestamp,
                  image: img.image,
                  ...img.geolocation,
                  label: `${imageType} ${imgIndex + 1} (Crop ${
                    cropIndex + 1
                  }: ${crop.crop})`,
                });
              }
            });
          }
        });
      }
    });
  }
  return [...geolocations].sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
  );
}

function ImageGeolocationMarkers({ currentData, pathOptions, showLine }) {
  const geolocations = extractImageGeolocations(currentData);
  const map = useMap();

  useEffect(() => {
    if (showLine && geolocations.length > 0 && currentData) {
      const polylinePoints = [
        [currentData.latitude, currentData.longitude],
        ...geolocations.map((geo) => [geo.latitude, geo.longitude]),
      ];
      const polyline = L.polyline(polylinePoints, {
        ...pathOptions,
        dashArray: "5, 5",
        color: "pink",
      }).addTo(map);

      return () => {
        map.removeLayer(polyline);
      };
    }
  }, [map, geolocations, pathOptions, currentData, showLine]);

  return geolocations.map((geo, index) => (
    <React.Fragment key={index}>
      <Marker
        position={[geo.latitude, geo.longitude]}
        icon={violetIcon}
        autoPan={false}
      >
        <Tooltip sticky={true}>
          <img src={getImageUrl(geo.image)} alt={geo.label} className="" />
          {geo.timestamp}
          <br />
          {geo.label}
          <br />
          {`Accuracy: ${Math.floor(geo.accuracy).toFixed(2)} m`}
        </Tooltip>
      </Marker>
    </React.Fragment>
  ));
}

export default ImageGeolocationMarkers;
