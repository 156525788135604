import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg";

const getBounds = (t) => {
  return [
    [t.longitude - t.width, t.latitude - t.width],
    [t.longitude + t.width, t.latitude + t.width],
  ];
};

export const createMap = (container, task) => {
  return new mapboxgl.Map({
    container: container,
    style: "mapbox://styles/mapbox/satellite-v9", // style URL
    interactive: true, // Needed for mapbox-gl-draw styling
    bounds: task.latitude ? getBounds(task) : null,
    maxBounds: task.latitude ? getBounds(task) : null,
  });
};

export const showTask = (map, task) => {
  const bounds = getBounds(task);
  // Allow map to show new point
  map.setMaxBounds(bounds);
  // Move the map to the central point
  map.fitBounds(bounds, { duration: 0 });
  // Restrict map to current viewport
  map.setMaxBounds(map.getBounds());
};
