import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg";

const getBounds = (t) => {
  return [
    [t.longitude - 1.5 * t.width, t.latitude - 1.5 * t.width],
    [t.longitude + 1.5 * t.width, t.latitude + 1.5 * t.width],
  ];
};
const getPolygon = (t) => {
  const coords = t
    ? [
        [t.longitude - t.width, t.latitude - t.width],
        [t.longitude - t.width, t.latitude + t.width],
        [t.longitude + t.width, t.latitude + t.width],
        [t.longitude + t.width, t.latitude - t.width],
        [t.longitude - t.width, t.latitude - t.width],
      ]
    : [];
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coords],
    },
  };
};

export const createMap = (container, task) => {
  const map = new mapboxgl.Map({
    container: container,
    style: "mapbox://styles/mapbox/streets-v12", // style URL
    interactive: true, // Needed for mapbox-gl-draw styling
    bounds: task?.latitude ? getBounds(task) : null,
    maxBounds: task?.latitude ? getBounds(task) : null,
  });
  map.addControl(new mapboxgl.ScaleControl());

  // Instead of restricting map to current viewport,
  // we show a yellow bounding box for the area to be delineated
  map.on("load", () => {
    map.addSource("maxar-tilesets", {
      type: "raster",
      tiles: [
        "https://titiler.xyz/mosaicjson/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=https%3A%2F%2Fgta-cog.s3.eu-central-1.amazonaws.com%2Fgta_raster_maxar.json.gz",
      ],
    });
    map.addLayer({
      id: "maxar-tiles-layer",
      type: "raster",
      source: "maxar-tilesets",
      minzoom: 0,
      maxzoom: 22,
      layout: {
        visibility: "visible",
      },
    });

    map.addSource("task-boundary", {
      type: "geojson",
      data: getPolygon(task),
    });
    map.addLayer({
      id: "task-outline",
      type: "line",
      source: "task-boundary",
      layout: {},
      paint: {
        "line-color": "#ff0",
        "line-width": 3,
      },
    });
  });
  return map;
};

export const showTask = (map, task) => {
  const bounds = getBounds(task);
  // Allow map to show new point
  // Move the map to the central point
  map.fitBounds(bounds, { duration: 0 });

  // Add the boundary box
  map.getSource("task-boundary").setData(getPolygon(task));
};
