import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useRef } from "react";
import { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import dataHeader from "../../data/header.js";
import delineation2Header from "../../delineation2/header.js";
import delineationHeader from "../../delineation/header.js";
import landcover2Header from "../../landcover2/header.js";
import landcoverHeader from "../../landcover/header.js";
import userHeader from "../../user/header.js";
import logo from "../logo.svg";

function HeaderLink({ header, children, onClick }) {
  const props = {
    onClick,
    className:
      "-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50",
  };
  if (header.external) {
    return (
      <a href={header.to} {...props} target="_blank">
        {children}
      </a>
    );
  } else {
    return (
      <Link to={header.to} {...props}>
        {children}
      </Link>
    );
  }
}

function Header({ t }) {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const buttonRef = useRef();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const prefixHeaders = (makeHeaders, prefix, addSpacer = true) => {
    const headers = makeHeaders(user, t).map((h) => {
      return h.external ? h : { ...h, to: prefix + "/" + h.to };
    });
    if (headers.length > 0 && addSpacer) {
      headers.push(null);
    }
    return headers;
  };

  const headers = [
    ...prefixHeaders(dataHeader, "/data"),
    ...prefixHeaders(delineationHeader, "/delineation"),
    ...prefixHeaders(delineation2Header, "/delineation2"),
    ...prefixHeaders(landcoverHeader, "/landcover"),
    ...prefixHeaders(landcover2Header, "/landcover2"),
    ...prefixHeaders(userHeader, "/users", false),
  ];

  return (
    <header className="bg-cyan-700 shadow">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full items-center justify-between py-3 sm:flex">
          <div className="flex items-center">
            <Link
              to="/"
              className="text-white text-xl sm:text-2xl font-base flex"
            >
              <div className="h-10 w-10 pt-1 pl-0.5 bg-cyan-500 rounded-lg">
                <img className="h-full" src={logo} alt="" />
              </div>
              <span className="ml-4 leading-10 align-middle">
                {t(
                  "common.groundTruthDataCollection",
                  "Ground Truth Data Collection",
                )}
              </span>
            </Link>
          </div>
          <div className="mt-4 sm:mt-0 flex flex-row-reverse">
            {user ? (
              <React.Fragment>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={buttonRef}
                        as="div"
                        className={classNames(
                          open ? "text-gray-200" : "text-gray-100",
                          "group inline-flex py-2 text-sm font-medium text-white hover:text-gray-200",
                        )}
                      >
                        <span>{t("user.account", "Account")}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-200" : "text-gray-100",
                            "ml-2 h-5 w-5 group-hover:text-gray-200",
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 px-3 z-10 mt-2 w-screen max-w-xs transform px-2 sm:px-0">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 bg-white px-5 py-4 sm:gap-6 sm:p-8">
                              {headers.map((h, i) => {
                                if (h) {
                                  return (
                                    <HeaderLink
                                      header={h}
                                      key={h.to}
                                      onClick={() => {
                                        buttonRef.current?.click();
                                      }}
                                    >
                                      <p className="text-base font-medium text-gray-900">
                                        {h.title}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {h.desc}
                                      </p>
                                    </HeaderLink>
                                  );
                                } else {
                                  return (
                                    <div className="relative" key={i}>
                                      <div
                                        className="absolute inset-0 flex items-center"
                                        aria-hidden="true"
                                      >
                                        <div className="w-full border-t border-gray-300" />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                              <button
                                type="submit"
                                onClick={() => {
                                  logout();
                                  open = false;
                                  navigate("/");
                                }}
                                className="inline-block rounded-md border border-transparent bg-white py-2 px-4 text-sm font-medium text-cyan-600 hover:bg-gray-100"
                              >
                                {t("user.logOut", "Log out")}
                              </button>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Link
                  to="/users/signup"
                  className="ml-4 inline-block rounded-md border border-transparent bg-white py-2 px-4 text-sm font-medium text-cyan-600 hover:bg-gray-100"
                >
                  {t("user.signUp", "Sign up")}
                </Link> */}
                <Link
                  to="/users/login"
                  className="inline-block rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-sm font-medium text-white hover:bg-opacity-75"
                >
                  {t("user.signIn", "Sign in")}
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default withTranslation()(Header);
