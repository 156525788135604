import { Field } from "formik";

export default function Radio({ label, name, options, validate }) {
  const texts = Array.isArray(options) ? options : Object.values(options);
  const values = Array.isArray(options) ? options : Object.keys(options);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <legend className="block text-sm font-medium text-gray-700 sm:mt-1 sm:pt-2">
        {label}
      </legend>
      <fieldset>
        <div className="mt-1 space-y-4">
          {options &&
            values.map((v, i) => (
              <div key={i} className="flex items-center">
                <Field
                  value={v}
                  data-testid={`radio-${v}`}
                  name={name}
                  type="radio"
                  validate={validate}
                  className="h-4 w-4 border-gray-300 text-cyan-600 focus:ring-cyan-500"
                />
                <label
                  htmlFor="crop-barley"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {texts[i]}
                </label>
              </div>
            ))}
        </div>
      </fieldset>
    </div>
  );
}
