import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useEffect } from "react";

export default function Alert({ type, message, show, setAlert }) {
  const clearAlert = useCallback(
    () =>
      setAlert((alert) => {
        return { ...alert, show: false };
      }),
    [setAlert],
  );

  // Clear non-error alert after 2 seconds
  useEffect(() => {
    if (show && type !== "error") {
      setTimeout(clearAlert, 2000);
    }
  }, [show, clearAlert]);

  var color;
  if (type === "success") {
    color = "green";
  } else if (type === "warning") {
    color = "yellow";
  } else if (type === "error") {
    color = "red";
  } else {
    if (message) {
      throw Error("bad color");
    }
  }

  const iconClass = `h-5 w-5 text-${color}-400`;
  var icon;
  if (type === "success") {
    icon = <CheckCircleIcon className={iconClass} aria-hidden="true" />;
  } else if (type === "warning") {
    icon = <ExclamationCircleIcon className={iconClass} aria-hidden="true" />;
  } else if (type === "error") {
    icon = <XCircleIcon className={iconClass} aria-hidden="true" />;
  }

  return (
    <Transition
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`rounded-md bg-${color}-50 p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">{icon}</div>
          <div className="ml-3">
            <p
              className={`text-sm font-medium text-${color}-800 whitespace-pre`}
            >
              {message}
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex rounded-md bg-${color}-50 p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-${color}-600 focus:ring-offset-2 focus:ring-offset-${color}-50`}
                onClick={clearAlert}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
