import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { post, unwrapError } from "../../common/utils/api";

function VerifyEmail({ showAlert, t }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    post("/users/verify_email/", {
      email: searchParams.get("email"),
      token: searchParams.get("token"),
    })
      .then(function (response) {
        showAlert({ type: "success", message: response.data });
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      })
      .finally(() => navigate("/users/login"));
  }, [searchParams, navigate, showAlert]);

  return (
    <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
      {t("user.verifyingEmail", "Verifying email...")}
    </div>
  );
}

export default withTranslation()(VerifyEmail);
