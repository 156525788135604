import {
  CalendarIcon,
  CheckCircleIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import { get } from "../../common/utils/api";
import { createMap, showTask } from "../utils/map";

function DelineationSummaryDetail({ t }) {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const { user } = useUser();

  let [task, setTask] = useState({});
  const parcels = task.parcel2_set || [];

  const numApproved = parcels.reduce((a, b) => a + (b.is_approved === true), 0);
  const numRejected = parcels.reduce(
    (a, b) => a + (b.is_approved === false),
    0,
  );

  const [searchParams] = useSearchParams();
  const taskSelected = searchParams.get("task_id");

  let [showParcels, setShowParcels] = useState(true);

  useEffect(() => {
    if (mapContainer.current === null) return; // need map container to be showing
    if (map.current) return; // initialize map only once
    map.current = createMap(mapContainer.current, task);

    map.current.on("load", () => {
      setMapLoaded(true);
    });
  });

  function refreshMap() {
    if (!mapLoaded) return;
    if (task.latitude === undefined) return;

    showTask(map.current, task);

    // Draw the central point
    const newData = {
      type: "FeatureCollection",
      features: parcels.map((p) => p.data),
    };
    const geojsonSource = map.current.getSource("parcels");
    if (geojsonSource !== undefined) {
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(newData);
    } else {
      // Create GeoJSON source
      map.current.addSource("parcels", {
        type: "geojson",
        data: newData,
        generateId: true,
      });

      map.current.addLayer({
        id: "parcels",
        type: "fill",
        source: "parcels",
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "approved"], false],
            "#00ff00",
            [
              "case",
              ["boolean", ["feature-state", "rejected"], false],
              "#ff0000",
              "#f0f0f0",
            ],
          ],
          "fill-opacity": 0.2,
        },
      });
      map.current.addLayer({
        id: "parcelsOutline",
        type: "line",
        source: "parcels",
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "active"], false],
            "#00f",
            "#000",
          ],
          "line-width": 2,
          "line-opacity": 0.5,
        },
      });

      // Change the cursor to a pointer when
      // the mouse is over the states layer.
      map.current.on("mouseenter", "parcels", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Change the cursor back to a pointer
      // when it leaves the states layer.
      map.current.on("mouseleave", "parcels", () => {
        map.current.getCanvas().style.cursor = "";
      });
    }
  }
  useEffect(refreshMap, [task, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    map.current.setLayoutProperty(
      "parcels",
      "visibility",
      showParcels ? "visible" : "none",
    );
    map.current.setLayoutProperty(
      "parcelsOutline",
      "visibility",
      showParcels ? "visible" : "none",
    );
  }, [showParcels, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    if (task.latitude === undefined) return;
    parcels.forEach((p, i) => {
      map.current.setFeatureState(
        { source: "parcels", id: i },
        { approved: p.is_approved === true },
      );
      map.current.setFeatureState(
        { source: "parcels", id: i },
        { rejected: p.is_approved === false },
      );
    });
  }, [task, mapLoaded]);

  const getData = useCallback(async () => {
    get(`/parcels2/summary/detail/?task_id=${taskSelected}`).then(
      (response) => {
        setTask(response.data);
      },
    );
  }, [taskSelected, user]);

  useEffect(() => {
    getData();
  }, [getData]);

  return user ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Delineation Submission
                </h1>
              </div>
              <div className="flex">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {"Date collection date: " +
                    new Date(task.created_at).toLocaleString()}
                </span>
              </div>
              <div className="flex">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {"Image acquisition date: " +
                    new Date(task.date).toLocaleString()}
                </span>
              </div>
              <div className="flex">
                <UserIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {task.user?.first_name} {task.user?.last_name}
                </span>
              </div>
              <div className="flex">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {task.user?.email}
                </span>
              </div>
            </div>
            {/*Approval block*/}
            <div className="mt-4 flex flex-col items-center justify-end">
              {/*Statuses */}
              <div className="flex flex-row space-x-4 justify-center">
                <div className="flex">
                  <CheckCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-green-500"
                    data-testid="approved-parcels"
                  >
                    {numApproved}
                  </span>
                </div>
                <div className="flex">
                  <XCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-red-500"
                    data-testid="rejected-parcels"
                  >
                    {numRejected}
                  </span>
                </div>
                <div className="flex">
                  <QuestionMarkCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-gray-500"
                    data-testid="not-reviewed-parcels"
                  >
                    {parcels.length - numApproved - numRejected}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="relative mt-4 ">
            <div
              id="map"
              ref={mapContainer}
              className="map-container aspect-square max-h-[75vh] mx-auto"
            ></div>
            <div
              className="w-full text-center"
              style={{
                position: "absolute",
                top: 0,
                zIndex: 9,
              }}
            >
              <button
                onClick={() => setShowParcels(!showParcels)}
                className="mt-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                {showParcels
                  ? t("common.hideParcels", "Hide Parcels")
                  : t("common.showParcels", "Show Parcels")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(DelineationSummaryDetail);
