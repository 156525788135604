import { Form, Formik } from "formik";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { post, unwrapError } from "../../common/utils/api";
import EmailInput from "../components/EmailInput.js";
import PasswordInput from "../components/PasswordInput.js";
import { useUser } from "../utils/user";

function Login({ t }) {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState({});
  const { login } = useUser();

  function onSubmit(value, { setSubmitting }) {
    setErrorData({});
    post("/api-token-auth/", {
      username: value.email,
      password: value.password,
    })
      .then(function (response) {
        setErrorData({});
        login(response.data.token).then(() => navigate("/"));
      })
      .catch(function (error) {
        setErrorData(unwrapError(error, true));
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  }

  return (
    <div>
      <div className="mt-12 text-center text-2xl font-bold tracking-tight text-gray-900 lg:text-4xl">
        {t("user.signIn", "Sign in")}
      </div>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className="mt-6 mx-auto w-full max-w-sm">
            <p className="mb-6 text-center text-xs font-medium text-red-600">
              {errorData.non_field_errors || "\u00A0"}
            </p>
            <div className="mb-2">
              <EmailInput errorData={errorData} />
            </div>
            <div className="mb-2">
              <PasswordInput errorData={errorData} isConfirm={false} />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-cyan-700 text-white hover:bg-cyan-600 w-full disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              <span>{t("user.signInToAccount", "Sign in to account")}</span>
            </button>

            <Link
              to="/"
              className={`mt-1 inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 border border-gray-300 text-gray-700 w-full ${
                isSubmitting
                  ? "bg-gray-100 cursor-not-allowed"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              {t("common.cancel", "Cancel")}
            </Link>
            <p className="mt-8 text-center text-gray-900">
              <Link
                to="/users/password_reset"
                className="text-sm hover:underline"
              >
                {t("user.forgotPassword", "Forgot password?")}
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation()(Login);
