import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Form, Formik } from "formik";
import { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { withTranslation } from "react-i18next";
import {
  Circle,
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { useUser } from "../../user/utils/user.js";

import { get, patch, unwrapError } from "../../common/utils/api.js";
import CropItem from "../components/CropItem.js";
import ImageGeolocationMarkers from "../components/ImageGeolocations.js";
import DescriptionList from "../components/NewDataReview.js";
import { IrrigationInput } from "../components/Plot.js";
import { MIN_ACCURACY } from "../utils/location.js";
import { cleanDataBeforeSubmit } from "./Form.js";

const L = require("leaflet");
var blueIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
var redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const API_KEY = "AIzaSyCddB8v9ft_ATozIdkN23KBI4SPn-tyuBM";

const CustomMarker = ({ active, id, approve, defaultColor, f, setCurrent }) => {
  const map = useMap();
  const circleRefs = useRef();

  let color;
  if (approve === true) {
    color = "green";
  } else if (approve === false) {
    color = "red";
  } else {
    color = active ? "blue" : defaultColor;
  }

  useEffect(() => {
    if (circleRefs.current) {
      circleRefs.current.property = { id: id };
      circleRefs.current
        .addTo(map)
        .on("click", (e) => setCurrent(e.target.property.id));
    }
  }, [id, map, setCurrent]);

  useEffect(() => {
    if (circleRefs.current) {
      circleRefs.current.setStyle({
        color: color,
        fillOpacity: active ? 0.05 : 0,
      });
    }
  }, [color, map, active]);

  if (f.latitude && f.longitude) {
    return (
      <Circle
        center={{ lat: f.latitude, lng: f.longitude }}
        ref={(r) => {
          circleRefs.current = r;
        }}
        radius={f.manualLocation ? 5 : f.accuracy}
        pathOptions={f.manualLocation ? { dashArray: "4" } : {}}
      >
        {active && (
          <Marker
            position={{ lat: f.latitude, lng: f.longitude }}
            icon={f.lowAccuracy ? redIcon : blueIcon}
            autoPan={false}
          />
        )}
        <CircleMarker
          center={{ lat: f.latitude, lng: f.longitude }}
          color={defaultColor}
          fillOpacity={0}
          radius={1}
        />
      </Circle>
    );
  } else {
    return null;
  }
};

function ChangeView({ markers, current }) {
  const map = useMap();
  useEffect(() => {
    let markerBounds = latLngBounds([]);
    if (markers && markers.length > 0) {
      // Show all markers on first render
      markers.forEach((marker) => {
        if (marker.latitude && marker.longitude) {
          markerBounds.extend([marker.latitude, marker.longitude]);
        }
      });
      Object.keys(markerBounds).length > 0 && map.fitBounds(markerBounds);
    }
  }, [markers, map]);

  useEffect(() => {
    if (markers && markers.length > 0) {
      // For subsequent ones, zoom in unto the specific marker
      let marker = markers[current];
      if (marker.latitude && marker.longitude) {
        map.panTo([marker.latitude, marker.longitude]);
      }
    }
  }, [markers, current, map]);
  return null;
}

function EditDialog({ isOpen, onClose, data, t, submitEditData }) {
  const initialValues = { ...data };
  function onSubmit(value) {
    submitEditData(value);
    onClose();
  }
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                {data && (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <div>
                        <div className="text-center">
                          <Dialog.Title
                            as="h3"
                            className="text-2xl font-semibold leading-6 text-gray-900"
                          >
                            Edit Data Submission
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className="space-y-6 pt-6 sm:space-y-5 sm:pt-6">
                        {data?.crops.length > 0 &&
                          data.crops.map((_, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div>
                                  <h3
                                    className="text-lg font-medium leading-6 text-gray-900 pt-8 sm:border-t sm:border-gray-200"
                                    key={index}
                                  >
                                    Crop {index + 1}
                                  </h3>
                                </div>
                                <CropItem index={index} images={false} t={t} />
                              </React.Fragment>
                            );
                          })}
                        <div>
                          <h3 className="text-lg font-medium leading-6 text-gray-900 pt-8 sm:border-t sm:border-gray-200">
                            Additional Information
                          </h3>
                        </div>
                        <IrrigationInput t={t} />
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </Formik>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function ReviewedParcelSingle({ showAlert, t }) {
  const { user } = useUser();
  const canReview = user.user.is_supervisor;

  let [allData, setAllData] = useState([]);
  let [current, setCurrent] = useState(0);
  const currentData = allData[current];
  const numApproved = allData.filter((d) =>
    d.questions.every((q) => q.is_approved === true),
  ).length;
  const numRejected = allData.filter((d) =>
    d.questions.some((q) => q.is_approved === false),
  ).length;

  const [searchParams] = useSearchParams();
  const userSelected = searchParams.get("user");
  const dateSelected = searchParams.get("date");

  let [polyline, setPolyline] = useState([]);
  let [position, setPosition] = useState([6.0, -3.8]);
  let [useSatellite, setUseSatellite] = useState(false);
  const defaultColor = useSatellite ? "#fafafa" : "gray";
  const pathOptions = { color: defaultColor };

  const [showRejected, setShowRejected] = useState(false);
  // const [autoNext, setAutoNext] = useState(true);
  const [showLine, setShowLine] = useState(true);

  const [isSubmitting, setSubmitting] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);

  const getData = useCallback(async () => {
    get(`/data/detail/?username=${userSelected}&date=${dateSelected}`).then(
      (response) => {
        let data = response.data;
        setRemaining(
          data.length -
            data.filter((d) =>
              d.questions.every((q) => q.review_complete === true),
            ).length,
        );
        data = data
          .filter((d) => d.questions.every((q) => q.review_complete !== false))
          .map((d) => {
            return {
              createddate: new Date(d.form_created_at),
              id: d.id,
              irrigation: (d.data.irrigation = "irrigated"),
              latitude: d.data.latitude,
              longitude: d.data.longitude,
              // Fallback for data points collected before accuracy was tracked
              accuracy: d.data.accuracy || 0,
              manualLocation: d.data.manualLocation,
              lowAccuracy:
                d.data.manualLocation || d.data.accuracy > MIN_ACCURACY,
              name: (d.data.name = "parcel"),
              crops: d.data.crops,
              questions: d.questions,
            };
          })
          .sort((a, b) => a.createddate - b.createddate);

        if (showRejected) {
          data = data.filter((d) =>
            d.questions.some((q) => q.is_approved === false),
          );
        }

        setAllData(data);
        const tmpPolyline = [];
        data.forEach((d) => {
          if (d.latitude && d.longitude) {
            tmpPolyline.push([d.latitude, d.longitude]);
          }
        });
        setPolyline(tmpPolyline);
        setPosition([data[0].latitude, data[0].longitude]);
      },
    );
  }, [userSelected, dateSelected, showRejected, user]);

  useEffect(() => {
    getData();
    setCurrent(0);
  }, [getData]);

  const handleShowRejectedCheckbox = () => {
    setShowRejected(!showRejected);
  };

  const handleShowLineCheckbox = () => {
    setShowLine(!showLine);
  };

  const isEnd = current === allData.length - 1;
  function previous() {
    if (current !== 0) {
      setCurrent(current - 1);
    }
  }
  function next() {
    if (!isEnd) {
      setCurrent(current + 1);
    }
  }

  function submitEditData(data) {
    data = cleanDataBeforeSubmit(data, t);
    const toPost = {
      irrigation: data.irrigation,
      crops: data.crops,
    };
    patch(`/data/edit/${currentData.id}`, { data: toPost })
      .then((response) => {
        setAllData((d) => {
          const out = [...d];
          out[current] = {
            ...out[current],
            irrigation: response.data.data.irrigation,
            crops: response.data.data.crops,
          };
          return out;
        });
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully edited"),
        });
      })
      .catch((error) => {
        showAlert({ type: "error", message: unwrapError(error) });
      });
  }

  return user ? (
    <React.Fragment>
      <EditDialog
        isOpen={editIsOpen}
        onClose={() => setEditIsOpen(false)}
        data={currentData}
        t={t}
        submitEditData={submitEditData}
      />
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-start md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-start">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Activity Report
                </h1>
              </div>
              <div className="md:flex md:space-x-2">
                <div className="flex">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-gray-500" data-testid="date">
                    {dateSelected}
                  </span>
                </div>
                <div className="flex">
                  <UserIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-gray-500"
                    data-testid="userName"
                  >
                    {currentData?.user}
                  </span>
                </div>
                <div className="flex">
                  <EnvelopeIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-gray-500" data-testid="email">
                    {userSelected}
                  </span>
                </div>
              </div>
            </div>
            {/*Approval block*/}
            <div className="mt-4 flex flex-col items-center justify-end">
              {/*Statuses */}
              <div className="flex flex-row space-x-4 justify-center">
                <div className="flex">
                  <CheckCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-green-500"
                    data-testid="approve-total"
                  >
                    {numApproved}
                  </span>
                </div>
                <div className="flex">
                  <XCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-red-500"
                    data-testid="reject-total"
                  >
                    {numRejected}
                  </span>
                </div>
                <div className="flex">
                  <QuestionMarkCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-gray-500"
                    data-testid="remaining-total"
                  >
                    {remaining}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <MapContainer
            className="mt-4 border border-gray-200"
            center={position}
            style={{ height: 400, position: "relative" }}
            zoom={7}
          >
            <ChangeView markers={allData} current={current} />
            <div
              className="m-2 justify-end"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 9,
              }}
            >
              <button
                onClick={() => setUseSatellite(!useSatellite)}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                {useSatellite
                  ? t("common.changeToStreet", "Change to Street")
                  : t("common.changeToSatellite", "Change to Satellite")}
              </button>
            </div>
            {useSatellite ? (
              <ReactLeafletGoogleLayer apiKey={API_KEY} type={"satellite"} />
            ) : (
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            )}
            {allData.map((f, index) => (
              <CustomMarker
                key={index}
                id={index}
                active={index === current}
                defaultColor={defaultColor}
                f={f}
                setCurrent={setCurrent}
              />
            ))}
            {showLine && (
              <Polyline pathOptions={pathOptions} positions={polyline} />
            )}
            <ImageGeolocationMarkers
              currentData={currentData}
              pathOptions={pathOptions}
              showLine={showLine}
            />
          </MapContainer>
        </div>
        {currentData && (
          <React.Fragment>
            <div className="mt-4 flex flex-wrap items-center justify-between px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1"></div>
              <nav
                className="isolate inline-flex space-x-2 sm:space-x-4 rounded-md"
                aria-label="Pagination"
              >
                <div>
                  <button
                    type="button"
                    onClick={previous}
                    disabled={current === 0 || isSubmitting}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Previous</span>

                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex justify-center">
                  <div
                    className={`relative inline-flex items-center border border-gray-300 rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:z-20 ${
                      canReview || "rounded-r-md"
                    } ${isSubmitting && "opacity-50"}`}
                  >
                    <span className="sm:hidden">#</span>
                    <span className="hidden sm:inline-block">Record</span>
                    &nbsp;
                    {current + 1} / {allData.length}
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={next}
                    disabled={isEnd || isSubmitting}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </nav>
              <div className="-mt-px flex flex-1 justify-end">
                <div className="flex h-5 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={showRejected}
                    onChange={handleShowRejectedCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Only show rejections
                  </label>
                </div>
                <div className="ml-3 flex h-5 items-center">
                  <input
                    data-testid="show-line"
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={showLine}
                    onChange={handleShowLineCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Show line
                  </label>
                </div>
              </div>
            </div>
            <Tooltip id="unapproved-tooltip" className="z-50" />
            <DescriptionList
              data={currentData}
              current={current}
              showEdit={() => setEditIsOpen(true)}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(ReviewedParcelSingle);
