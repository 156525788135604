import { ErrorMessage, Field } from "formik";

export default function TextInput({ label, name, required, validate }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
        {required ? (
          <span className="text-red-800 text-superscript">*</span>
        ) : null}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <Field
          type="text"
          name={name}
          id={name}
          validate={validate}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
        />
        <p className={`text-sm font-medium text-red-800 mt-0`}>
          <ErrorMessage name={name} />
        </p>
      </div>
    </div>
  );
}
