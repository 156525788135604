import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";

import { useUser } from "../../user/utils/user";

import LoadingModal from "../../common/components/LoadingModal.js";
import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";
import { get } from "../../common/utils/api";
import { formatDate } from "../../common/utils/helpers";
import { getDatesBetweenDates } from "./SummarySupervisor";

function ReviewForm({ user, userOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <Select
            label={"User email"}
            name={"formUser"}
            options={userOptions}
          />
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.generate", "Generate Report")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function groupByUsernameAndDate(data) {
  // Group data by username and date
  const groupedData = data.reduce((acc, curr) => {
    const date = new Date(curr.updated_at).toISOString().split("T")[0];

    if (!acc[curr.username]) {
      acc[curr.username] = {
        username: curr.username,
        name: curr.first_name + " " + curr.last_name,
        num_reviews: 0,
      };
    }
    if (!acc[curr.username][date]) {
      acc[curr.username][date] = 0;
    }
    acc[curr.username][date] += 1;
    acc[curr.username].num_reviews += 1;

    return acc;
  }, {});

  return Object.values(groupedData);
}

function ReviewerSummary({ showAlert, t }) {
  let [userOptions, setUserOptions] = useState([]);
  let [summaryData, setSummaryData] = useState({
    groupedByDate: [],
    groupedByDateCols: [],
  });

  const { user } = useUser();

  // Default date range: past three days for now
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 3);

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formUser: sessionStorage.getItem("user") || "All",
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
  };

  const getUsers = async () => {
    get("/users/").then((response) => {
      let uniqueUsers = Object.assign(
        { All: "All" },
        ...response.data
          .filter((x) => x.is_supervisor)
          .sort(function (a, b) {
            return a.first_name.localeCompare(b.first_name);
          })
          .map((x) => ({
            [x.email]: x.first_name + " " + x.last_name + " (" + x.email + ")",
          })),
      );
      setUserOptions(uniqueUsers);
    });
  };

  const getData = async (
    user_selected,
    start_date,
    end_date,
    setSubmitting,
  ) => {
    get(
      `/data/review/activity/?username=${user_selected}&start_date=${start_date}&end_date=${end_date}`,
    )
      .then((response) => response.data)
      .then((data) => {
        data = data.sort(function (a, b) {
          let order1 = a.username?.localeCompare(b.username);
          let order2 = a.date?.localeCompare(b.date);

          if (order2 === 0) {
            return order1;
          } else {
            return -order2;
          }
        });

        const columnDefsByDate = [
          { field: "username", headerName: t("user.user", "User"), width: 200 },
          { field: "name", headerName: t("user.name", "Name"), width: 200 },
          {
            field: "num_reviews",
            headerName: t("user.review_complete", "# Reviewed"),
            width: 200,
            type: "numericColumn",
          },
        ];

        let uniqueDates = getDatesBetweenDates(start_date, end_date);
        uniqueDates.forEach((d) => {
          columnDefsByDate.push({
            field: d,
            width: 120,
            type: "numericColumn",
          });
        });

        setSummaryData({
          groupedByDate: groupByUsernameAndDate(data),
          groupedByDateCols: columnDefsByDate,
        });
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const gridUsername = useRef(null);
  const gridDate = useRef(null);

  const onGridReady = (params, grid) => {
    grid.current = params.api;
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    getUsers();
  }, []);
  const exportData = (grid) => {
    grid.current.exportDataAsCsv({
      allColumns: true,
      processCellCallback: (v) =>
        v.column.colDef.type === "combinedNumericColumn" ? v.value[0] : v.value,
    });
  };

  function onSubmit(value, { setSubmitting }) {
    getData(
      value.formUser,
      value.formStartDate,
      value.formEndDate,
      setSubmitting,
    );
    sessionStorage.setItem("user", value.formUser);
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Review Summary")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <LoadingModal open={isSubmitting} />
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm user={user} userOptions={userOptions} t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Daily Review Activity")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(gridDate)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div data-testid="summaryByDate">
          <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
            <AgGridReact
              ref={gridDate}
              rowData={summaryData.groupedByDate}
              columnDefs={summaryData.groupedByDateCols}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              onGridReady={(params) => onGridReady(params, gridDate)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReviewerSummary);
