import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import Signup from "./pages/Signup";
import User from "./pages/User";
import VerifyEmail from "./pages/VerifyEmail";

const routes = [
  { path: "login", element: Login, openAccess: true },
  // { path: "signup", element: Signup, openAccess: true },
  { path: "current", element: User },
  {
    path: "verify_email/confirm",
    element: VerifyEmail,
    openAccess: true,
  },
  { path: "password_reset", element: ResetPassword, openAccess: true },
  {
    path: "password_reset/confirm",
    element: ResetPasswordConfirm,
    openAccess: true,
  },
];
export default routes;
