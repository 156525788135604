const makeHeaders = (user, t) => {
  const header = [];

  if (user && user.user.roles?.includes("Data")) {
    const newReview = user.user?.new_review ?? false;
    const oldReview = user.user?.old_review ?? true;
    const needsPrefix = newReview && oldReview;
    let prefix;

    header.push({
      to: "task",
      title: t("user.dataCollection", "Data Collection"),
      desc: t("user.collectAndSubmitData", "Collect and submit data."),
    });

    if (oldReview) {
      prefix = needsPrefix ? "(OLD) " : "";

      header.push({
        to: "review/user",
        title: prefix + t("user.activitySummary", "Activity Summary"),
        desc: t(
          "user.reviewYourSubmittedData",
          "Review your data submissions.",
        ),
      });

      if (user.user.is_supervisor || user.user.is_superuser) {
        header.push({
          to: "summary",
          title: prefix + t("user.summaryView", "Supervisor Summary"),
          desc: t("user.TODO", "View summary of submissions."),
        });

        header.push({
          to: "review",
          title: prefix + t("user.review", "Review Submissions"),
          desc: t(
            "user.reviewSubmittedResults",
            "Review and approve submitted results.",
          ),
        });

        header.push({
          to: "review/activity",
          title: prefix + t("user.reviewerActivityView", "Reviewer Activity"),
          desc: t("user.TODO", "View reviewer activity."),
        });
      }
    }

    if (newReview) {
      prefix = needsPrefix ? "(NEW) " : "";
      if (user.user.is_supervisor || user.user.is_reviewer === false) {
        header.push({
          to: "summary-as",
          title: prefix + t("user.summaryAgentSupervisor", "Activity Summary"),
          desc: t("user.TODO", "View summary of submissions."),
        });
      }

      if (user.user.is_reviewer) {
        header.push({
          to: "review/queue",
          title: prefix + "Review Submissions",
          desc: t("user.reviewData", "Review collected data."),
        });
      }
      if (user.user.is_reviewer || user.user.is_superuser) {
        header.push({
          to: "reviewer",
          title: prefix + t("user.reviewerSummary", "Reviewer Activity"),
          desc: t("user.reviewSubmittedResults", "View summary of reviews."),
        });
      }
    }

    if (user.user.is_superuser) {
      header.push({
        to: "superuser",
        title: t("user.supervisorView", "Superuser View"),
        desc: t("user.viewTheSubmittedResults", "View all submitted data."),
      });
    }
  }
  return header;
};
export default makeHeaders;
