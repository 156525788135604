const makeHeaders = (user, t) => {
  const header = [];

  if (user && user.user.roles?.includes("Landcover2")) {
    header.push({
      to: "task",
      title: t("user.landcover", "Landcover"),
      desc: t("user.markLandcover", "Mark land cover on map."),
    });

    if (user.user.is_supervisor || user.user.is_superuser) {
      header.push({
        to: "review",
        title: t("user.landcoverReview", "Review Landcover"),
        desc: t("user.reviewLandcover", "Review land cover."),
      });
    }

    if (user.user.is_superuser) {
      header.push({
        to: "superuser",
        title: t("user.supervisorView", "Superuser View"),
        desc: t("user.viewTheSubmittedResults", "View all submitted data."),
      });
    }
  }
  return header;
};
export default makeHeaders;
