import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoadingModal from "../../common/components/LoadingModal.js";
import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";
import { get } from "../../common/utils/api";
import { downloadFile, formatDate } from "../../common/utils/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  const theDate = new Date(startDate);
  while (theDate < new Date(endDate)) {
    dates = [...dates, formatDate(new Date(theDate), "UTC")];
    theDate.setDate(theDate.getDate() + 1);
  }
  dates = [...dates, endDate];
  return dates;
};

function SummaryForm({ groups, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
          <Select label={"Group"} name={"formGroup"} options={groups} />
        </div>
        <div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              {t("common.generate", "Generate Report")}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Summary({ t }) {
  const [groupOptions, setGroupOptions] = useState([]);
  const [summaryData, setSummaryData] = useState({});

  const formRef = useRef();

  const navigate = useNavigate();

  // Default date range: past three days
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 30);

  const exportToCsv = (e) => {
    e.preventDefault();

    get(
      `/data/download/?start_date=${formRef.current.values.formStartDate}&end_date=${formRef.current.values.formEndDate}&group=${formRef.current.values.formGroup}`,
    ).then((response) => {
      downloadFile({
        data: response.data,
        fileName: "download.csv",
        fileType: "text/csv",
      });
    });
  };

  const getSummaryData = async (start_date, end_date, group, setSubmitting) => {
    get(
      `/data/summary_by_day/?start_date=${start_date}&end_date=${end_date}&group=${group}`,
    )
      .then((response) => {
        return response.data;
      })
      .then(function (data) {
        let userDetailsColumnDefs = [
          { field: "user", headerName: t("user.user", "User") },
          { field: "email", headerName: t("user.email", "Email") },
          {
            field: "total",
            headerName: t("common.total", "Total"),
            width: 120,
            type: "numericColumn",
          },
          {
            field: "approved",
            headerName: t("common.approved", "Approved"),
            type: "numericColumn",
          },
          {
            field: "reviewed",
            headerName: t("common.reviewed", "Reviewed"),
            type: "numericColumn",
          },
          {
            field: "approval_rate",
            headerName: t("common.approvalRate", "Approval Rate"),
            valueFormatter: (params) => {
              let val = params.data.approval_rate;
              if (val === -1) {
                return "-";
              } else {
                return (params.data.approval_rate * 100).toFixed(0) + "%";
              }
            },
            type: "numericColumn",
          },
          {
            field: "review_rate",
            headerName: t("common.reviewRate", "Review Rate"),
            valueFormatter: (params) => {
              return (params.data.review_rate * 100).toFixed(0) + "%";
            },
            type: "numericColumn",
          },
        ];
        let cropDetailsColumnDefs = [
          { field: "crop", headerName: t("crop.crop", "Crop") },
          {
            field: "total",
            headerName: t("common.total", "Total"),
            width: 120,
            type: "numericColumn",
          },
        ];

        let user_data = data.user_summary.map((d) => {
          d.approved = d.reviewed - d.rejected;
          return {
            user: d.first_name + " " + d.last_name,
            email: d.email,
            date: d.date,
            approved: d.approved,
            rejected: d.rejected,
            reviewed: d.reviewed,
            total: d.total,
            total_display: [
              d.total,
              d.reviewed > 0 ? d.approved / d.reviewed : -1,
            ],
          };
        });
        user_data = user_data.sort(function (a, b) {
          return b.date - a.date;
        });

        let crop_data = data.crop_summary.map((d) => {
          return {
            crop: d.data__crops__0__crop,
            date: d.form_created_date,
            total: d.n,
          };
        });
        crop_data = crop_data.sort(function (a, b) {
          return b.date - a.date;
        });

        let uniqueDates = getDatesBetweenDates(start_date, end_date);
        uniqueDates.forEach((d) => {
          cropDetailsColumnDefs.push({
            field: d,
            width: 120,
            type: "numericColumn",
          });
          userDetailsColumnDefs.push({
            field: d,
            width: 120,
            type: "combinedNumericColumn",
            cellRenderer: function (params) {
              return (
                <div className="grid grid-cols-2">
                  <span className="text-right">
                    {params?.value[0] > 0 && params.value[0]}
                  </span>
                  <span className="ml-2 text-right">
                    {params?.value[1] >= 0 &&
                      "(" + (params.value[1] * 100).toFixed(0) + "%)"}
                  </span>
                </div>
              );
            },
            onCellClicked: function (params) {
              navigate(
                `/data/review/single?user=${params.data.email}&date=${params.colDef.field}`,
              );
            },
            cellClass: [
              "text-cyan-600",
              "hover:text-cyan-900",
              "cursor-pointer",
            ],
            comparator: (a, b) => {
              if (a[0] === b[0]) {
                return a[1] - b[1];
              } else {
                return a[0] - b[0];
              }
            },
          });
        });

        // Rearrange so the result goes [{user: abc, 2022-11-01: 123, 2022-11-02: 132, ... }, ...]
        var userDetails = [];
        user_data.reduce(function (res, value) {
          if (!res[value.email]) {
            res[value.email] = {
              email: value.email,
              user: value.user,
              total: 0,
              approved: 0,
              reviewed: 0,
            };

            uniqueDates.forEach((date) => {
              res[value.email][date] = [0, -1];
            });
            userDetails.push(res[value.email]);
          }
          res[value.email][value.date] = value.total_display;
          res[value.email]["total"] += value.total;
          res[value.email]["approved"] += value.approved;
          res[value.email]["reviewed"] += value.reviewed;

          return res;
        }, {});
        userDetails = userDetails.sort(function (a, b) {
          return a.email.localeCompare(b.email);
        });
        userDetails.forEach((r) => {
          r["approval_rate"] =
            r["reviewed"] === 0 ? -1 : r["approved"] / r["reviewed"];
          r["review_rate"] = r["reviewed"] / r["total"];
        });

        // Do the same for crop detailed results
        var cropDetails = [];
        crop_data.reduce(function (res, value) {
          if (!res[value.crop]) {
            res[value.crop] = {
              crop: value.crop,
              total: 0,
            };

            uniqueDates.forEach((date) => {
              res[value.crop][date] = 0;
            });
            cropDetails.push(res[value.crop]);
          }
          res[value.crop][value.date] += value.total;
          res[value.crop]["total"] += value.total;
          return res;
        }, {});

        // Aggregate to total n for all users by day
        var dataByDayVals = [];
        user_data.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = 0;
            dataByDayVals = res;
          }

          res[value.date] += value.total;
          return res;
        }, {});

        const labels = uniqueDates;
        const numDataByDay = {
          labels,
          datasets: [
            {
              label: "Dataset 1",
              data: labels.map((l) => dataByDayVals[l]),
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        };

        // Show n active agents per day
        var agentsByDayVals = [];
        user_data.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = 0;
            agentsByDayVals = res;
          }

          if (value.total > 0) {
            res[value.date] += 1;
          }
          return res;
        }, {});
        const numAgentsByDay = {
          labels,
          datasets: [
            {
              label: "Dataset 1",
              data: labels.map((l) => agentsByDayVals[l]),
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        };

        const meanDataPerAgentByDay = {
          labels,
          datasets: [
            {
              label: "Dataset 1",
              data: labels.map(
                (l) => dataByDayVals[l] / agentsByDayVals[l] || 0,
              ),
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        };

        setSummaryData({
          userDetails,
          userDetailsColumnDefs,
          cropDetails,
          cropDetailsColumnDefs,
          numDataByDay,
          numAgentsByDay,
          meanDataPerAgentByDay,
        });
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const grid = useRef();
  const grid2 = useRef();

  const onGridReady = (params) => {
    grid.api = params.api;
  };
  const onGridReady2 = (params) => {
    grid2.api = params.api;
  };

  function onSubmit(value, { setSubmitting }) {
    getSummaryData(
      value.formStartDate,
      value.formEndDate,
      value.formGroup,
      setSubmitting,
    );
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
    sessionStorage.setItem("group", value.formGroup);
  }
  const initialValues = {
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formGroup: sessionStorage.getItem("group") || "All",
  };

  const getGroups = async () => {
    get("/users/groups/?old_review=true").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };
  useEffect(() => {
    getGroups();
  }, []);

  function exportData() {
    function getParams() {
      return {
        allColumns: true,
        processCellCallback: (v) => {
          if (v.column.colDef.type == "combinedNumericColumn") {
            return v.value[0];
          } else {
            return v.value;
          }
        },
      };
    }
    grid.api.exportDataAsCsv(getParams());
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white shadow rounded-md py-4 sm:py-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("common.summary", "Generate Summary Table")}
          </h3>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => (
            <React.Fragment>
              <LoadingModal open={isSubmitting} />
              <Form className="space-y-8 divide-y divide-gray-200">
                <SummaryForm groups={groupOptions} t={t} />
              </Form>
            </React.Fragment>
          )}
        </Formik>
      </div>
      <div className="mt-16">
        <div className="space-y-8">
          {summaryData.userDetails?.length > 0 ? (
            <div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  onClick={exportToCsv}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                >
                  {t("common.downloadData", "Download data")}
                </button>
              </div>
              <div className="sm:flex-auto bg-white p-4 shadow rounded-md">
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Number of records by date",
                      },
                    },
                  }}
                  data={summaryData.numDataByDay}
                  height={100}
                />
              </div>
              <div className="sm:flex-auto bg-white p-4 shadow rounded-md">
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Number of active agents by date",
                      },
                    },
                  }}
                  data={summaryData.numAgentsByDay}
                  height={100}
                />
              </div>
              <div className="sm:flex-auto bg-white p-4 shadow rounded-md">
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Average number of records per active agent by date",
                      },
                    },
                  }}
                  data={summaryData.meanDataPerAgentByDay}
                  height={100}
                />
              </div>
            </div>
          ) : (
            <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
                  There are no records in this time period
                </p>
              </div>
            </div>
          )}
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t(
                "common.numberOfRecordsForEachUserByDate",
                "Number of Records for each user by date",
              )}
            </p>
            <div className="mb-2 flex justify-end">
              <button
                onClick={exportData}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                {t("common.exportToCSV", "Export to CSV")}
              </button>
            </div>
            <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
              <AgGridReact
                ref={grid}
                rowData={summaryData.userDetails}
                columnTypes={{ combinedNumericColumn: {} }}
                columnDefs={summaryData.userDetailsColumnDefs}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                }}
                onGridReady={onGridReady}
              />
            </div>
          </div>
          <div className="sm:flex-auto">
            <p className="text-lg font-bold text-center text-gray-900 tracking-tight">
              {t(
                "common.numberOfRecordsForEachUserByDate",
                "Number of Records for each crop by date",
              )}
            </p>
            <div className="mt-2 ag-theme-alpine" style={{ height: 400 }}>
              <AgGridReact
                ref={grid2}
                rowData={summaryData.cropDetails}
                columnDefs={summaryData.cropDetailsColumnDefs}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                }}
                onGridReady={onGridReady2}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(Summary);
