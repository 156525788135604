import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import LoadingModal from "../../common/components/LoadingModal.js";
import DateInput from "../../common/components/form/DateInput.js";
import Radio from "../../common/components/form/Radio.js";
import Select from "../../common/components/form/Select.js";
import { get } from "../../common/utils/api";
import { formatDate } from "../../common/utils/helpers";

function ReviewForm({ userOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          <Select
            label={"User email"}
            name={"formUser"}
            options={userOptions}
          />
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
          <Radio
            label={"Include already-reviewed days"}
            name={"formShowCompleted"}
            options={{
              yes: "yes",
              no: "no",
            }}
          />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.generate", "Generate Report")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function DelineationReviewSummary({ showAlert, t }) {
  let [summaryData, setSummaryData] = useState([]);
  let [userOptions, setUserOptions] = useState([]);

  const navigate = useNavigate();
  const { user } = useUser();

  // Default date range: past three days for now
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 3);

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formUser: sessionStorage.getItem("user") || "All",
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formShowCompleted: sessionStorage.getItem("showCompleted") || "no",
  };

  let summaryColumnDefs = [
    {
      field: "review",
      headerName: "",
      width: 80,
      cellRenderer: () => t("plot.review", "review"),
      onCellClicked: function (params) {
        navigate(`/delineation2/review/single?task_id=${params.data.id}`);
      },
      cellClass: ["text-cyan-600", "hover:text-cyan-900", "cursor-pointer"],
    },
    { field: "user", headerName: t("user.user", "User") },
    { field: "email", headerName: t("user.email", "Email") },
    {
      field: "created_at",
      headerName: t("common.createddate", "Date"),
      width: 120,
    },
    {
      field: "review_rate",
      headerName: t("user.review_rate", "Own review rate"),
      width: 150,
      valueFormatter: (params) =>
        (params.data.review_rate * 100).toFixed(0) + "%",
      type: "numericColumn",
    },
    {
      field: "any_review_rate",
      headerName: t("user.any_review_rate", "Any review rate"),
      width: 150,
      valueFormatter: (params) =>
        (params.data.any_review_rate * 100).toFixed(0) + "%",
      type: "numericColumn",
    },
    {
      field: "approved",
      headerName: t("user.approved", "approved"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "reviewed",
      headerName: t("user.reviewed", "reviewed"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "total",
      headerName: t("user.total", "total"),
      width: 120,
      type: "numericColumn",
    },
  ];

  const getUsers = async () => {
    get("/users/").then((response) => {
      let uniqueUsers = Object.assign(
        { All: "All" },
        ...response.data
          .sort(function (a, b) {
            return a.first_name.localeCompare(b.first_name);
          })
          .map((x) => ({
            [x.email]: x.first_name + " " + x.last_name + " (" + x.email + ")",
          })),
      );
      setUserOptions(uniqueUsers);
    });
  };

  const getData = async (
    user_selected,
    start_date,
    end_date,
    show_completed,
    setSubmitting,
  ) => {
    // Format the start_date & end_date to 00:00 & 23:59 in local time
    const start_time = new Date(start_date + " 00:00:00.0").toISOString();
    const end_time = new Date(end_date + " 23:59:59.999").toISOString();
    get(
      `/parcels2/review/?username=${user_selected}&start_date=${start_time}&end_date=${end_time}`,
    )
      .then((response) => response.data)
      .then((data) => {
        data.forEach((d) => {
          d.created_at = new Date(d.created_at);
          d.user = d.first_name + " " + d.last_name;
          d.approved = d.reviewed - d.rejected;
          d.review_rate = d.total === 0 ? 0 : d.reviewed / d.total;
          d.any_review_rate = d.total === 0 ? 0 : d.any_reviewed / d.total;
        });

        // Show most recent data first, with user as the tiebreaker
        data = data.sort(function (a, b) {
          let order1 = a.email.localeCompare(b.email);
          let order2 = a.task_id - b.task_id;

          if (order2 === 0) {
            return order1;
          } else {
            return order2;
          }
        });

        data = data.filter((d) => {
          if (show_completed === "no") {
            return d["total"] > d["reviewed"];
          }
          return true;
        });

        setSummaryData(data);
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const grid = useRef();
  const onGridReady = (params) => {
    grid.api = params.api;
    grid.api.sizeColumnsToFit();
  };

  useEffect(() => {
    getUsers();
  }, []);

  function onSubmit(value, { setSubmitting }) {
    getData(
      value.formUser,
      value.formStartDate,
      value.formEndDate,
      value.formShowCompleted,
      setSubmitting,
    );
    sessionStorage.setItem("user", value.formUser);
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
    sessionStorage.setItem("showCompleted", value.formShowCompleted);
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Review Summary")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <LoadingModal open={isSubmitting} />
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm user={user} userOptions={userOptions} t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "Review Summary")}
            </h3>
          </div>
        </div>
        <div className="ag-theme-alpine mt-4" style={{ height: 400 }}>
          <AgGridReact
            ref={grid}
            rowData={summaryData}
            columnDefs={summaryColumnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(DelineationReviewSummary);
