import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { get } from "../../common/utils/api";

function UserReview({ t }) {
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState([]);

  const summaryColumnDefs = [
    {
      field: "review",
      headerName: "",
      width: 80,
      cellRenderer: () => t("plot.review", "review"),
      onCellClicked: function (params) {
        navigate(
          `/data/review/single?user=${params.data.email}&date=${params.data.date}`,
        );
      },
      cellClass: ["text-cyan-600", "hover:text-cyan-900", "cursor-pointer"],
    },
    {
      field: "date",
      headerName: t("common.createddate", "Date"),
      width: 120,
    },
    {
      field: "total",
      headerName: "Parcels",
      width: 120,
      type: "numericColumn",
    },
    {
      field: "approved",
      headerName: t("user.approved", "Approved"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "rejected",
      headerName: t("user.rejected", "Rejected"),
      width: 120,
      type: "numericColumn",
    },
  ];

  const getData = async () => {
    get("/data/review/user")
      .then((response) => response.data)
      .then((data) => {
        data.forEach((d) => {
          d.approved = d.reviewed - d.rejected;
        });

        data = data.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });

        setSummaryData(data);
      });
  };

  const grid = useRef();

  const onFirstDataRendered = useCallback((params) => {
    grid.current.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-8 flex flex-col">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("common.activitySummary", "Activity Summary")}
          </h3>
        </div>
      </div>
      <div className="mt-4 w-full max-w-2xl mx-auto">
        <div className="ag-theme-alpine" style={{ height: 400 }}>
          <AgGridReact
            ref={grid}
            rowData={summaryData}
            columnDefs={summaryColumnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(UserReview);
