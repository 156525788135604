import { isEqual } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { get } from "../../common/utils/api";
import {
  getItemFromStorage,
  removeItemFromStorage,
  setItemInStorage,
} from "../../common/utils/helpers";

export const USER_STORAGE_KEY = "user";

const UserContext = React.createContext({
  user: null,
  login: async () => {},
  refresh: async () => {},
  logout: () => {},
});

export const UserProvider = (props) => {
  const userCache = getItemFromStorage(USER_STORAGE_KEY) || null;
  const [user, setUser] = useState(userCache);

  const saveUser = (newUser) => {
    setUser((prevUser) => (isEqual(prevUser, newUser) ? prevUser : newUser));
    setItemInStorage(USER_STORAGE_KEY, newUser);
  };

  // Use token to get current user information and save it
  const login = async (token) => {
    return get("/users/current/", token).then((response) => {
      saveUser({ token, user: response.data });
    });
  };
  // Save refreshed user data or use cached token to get it if not supplied
  const refresh = async (newUser) => {
    if (newUser) {
      saveUser({ token: user.token, user: newUser });
    } else {
      await login(user.token);
    }
  };
  // Delete saved user state
  const logout = () => {
    setUser(null);
    removeItemFromStorage(USER_STORAGE_KEY);
  };

  // Periodically refresh the user information
  useEffect(() => {
    const update = () => {
      if (user && navigator.onLine) {
        refresh().catch(() => null);
      }
    };

    update();
    const interval = setInterval(update, 60000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, login, logout, refresh }} {...props} />
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const getToken = () => {
  return (getItemFromStorage(USER_STORAGE_KEY) || {}).token;
};
