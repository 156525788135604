import { Menu, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import { get } from "../../common/utils/api";
import Map from "../components/Map";
import Palette from "../components/Palette";

const formatScore = (s) => (s === null ? "-" : (s * 100).toFixed(0) + "%");

function LandcoverSuperuserSingle({ showAlert, t }) {
  const mapStatic = useRef(null);
  const mapSubmission = useRef(null);
  const mapReview = useRef(null);
  const categoryRef = useRef();

  const { user } = useUser();

  let [submission, setSubmission] = useState();

  const [searchParams] = useSearchParams();
  const submissionSelected = searchParams.get("submission_id");

  useEffect(() => {
    get(
      `/landcover/superuser/single/?submission_id=${submissionSelected}`,
    ).then((response) => {
      const submission = response.data;
      submission.task.grid = {
        type: "FeatureCollection",
        features: submission.data.map((d) => {
          return d.feature;
        }),
      };
      submission.initialLabels = submission.data.map((d) => d.label);
      submission.reviewLabels = submission.approved_labels.map((d) => d.label);
      setSubmission(submission);
    });
  }, [submissionSelected]);

  return user && submission ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Land Cover Submission
                </h1>
              </div>
              <div className="flex">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {new Date(submission?.created_at).toLocaleString()}
                </span>
              </div>
              <div className="flex">
                <UserIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {submission.user_name}
                </span>
              </div>
              <div className="flex">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {submission.user_email}
                </span>
              </div>
            </div>
            <div className="mt-4 flex flex-col items-center justify-end">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    disabled={!user.user.is_superuser}
                    className="flex px-4 py-2 items-center rounded-full text-gray-700 hover:text-gray-900 outline-none border border-1 shadow border-gray-300"
                  >
                    {/*Statuses */}
                    <div className="flex flex-row space-x-4 justify-center">
                      <p>Reviews: {submission.num_reviews}</p>
                      <p>Score: {formatScore(submission.review_score)}</p>
                    </div>
                  </Menu.Button>
                </div>
                {user.user.is_superuser &&
                  submission?.review_set?.length > 0 && (
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {submission?.review_set?.map((c, index) => (
                          <div
                            key={index}
                            className="py-2 text-gray-700 block px-4 py-2 text-sm"
                          >
                            {c.reviewer} - {formatScore(c.score)}
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  )}
              </Menu>
            </div>
          </div>
        </div>

        {submission && (
          <div className="mt-6 flex flex-row">
            <div className="flex-grow space-y-6">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-center font-medium text-lg text-gray-700">
                    Reference
                  </div>
                  <Map
                    map={mapStatic}
                    interactive={false}
                    task={submission.task}
                    categoryRef={categoryRef}
                  />
                </div>
                <div>
                  <div className="text-center font-medium text-lg text-gray-700">
                    User Submission
                  </div>
                  <Map
                    map={mapSubmission}
                    interactive={false}
                    task={submission.task}
                    categoryRef={categoryRef}
                    initialLabels={submission.initialLabels}
                  />
                </div>
                <div>
                  <div className="text-center font-medium text-lg text-gray-700">
                    Review Output
                  </div>
                  <Map
                    map={mapReview}
                    interactive={false}
                    task={submission.task}
                    categoryRef={categoryRef}
                    initialLabels={submission.reviewLabels}
                  />
                </div>
              </div>
            </div>

            <div className="mt-0 ml-6 flex-shrink-0 flex-grow-0">
              <Palette />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(LandcoverSuperuserSingle);
