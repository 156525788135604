import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import { get, post, unwrapError } from "../../common/utils/api";
import { createMap, showTask } from "../utils/map";

function DelineationReviewSingle({ showAlert, t }) {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const { user } = useUser();
  const canReview = user.user.is_supervisor;

  let [task, setTask] = useState({});
  const parcels = task.parcel_set || [];

  let [current, setCurrent] = useState(0);
  const currentData = parcels[current];

  function getApproval(d) {
    if (canReview) {
      return d.approval.approve;
    } else {
      if (d.approval_set.length === 0) {
        return null;
      } else {
        return d.approval_set.every((a) => a.approve);
      }
    }
  }

  const numApproved = parcels.reduce(
    (a, b) => a + (getApproval(b) === true),
    0,
  );
  const numRejected = parcels.reduce(
    (a, b) => a + (getApproval(b) === false),
    0,
  );

  const [searchParams] = useSearchParams();
  const taskSelected = searchParams.get("task_id");

  let [showParcels, setShowParcels] = useState(true);

  const [autoNext, setAutoNext] = useState(true);

  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (mapContainer.current === null) return; // need map container to be showing
    if (map.current) return; // initialize map only once
    map.current = createMap(mapContainer.current, task);

    map.current.on("load", () => setMapLoaded(true));
  });

  function refreshMap() {
    if (!mapLoaded) return;
    if (task.latitude === undefined) return;

    showTask(map.current, task);

    // Draw the central point
    const newData = {
      type: "FeatureCollection",
      features: parcels.map((p) => p.data),
    };
    const geojsonSource = map.current.getSource("parcels");
    if (geojsonSource !== undefined) {
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(newData);
    } else {
      // Create GeoJSON source
      map.current.addSource("parcels", {
        type: "geojson",
        data: newData,
        generateId: true,
      });

      map.current.addLayer({
        id: "parcels",
        type: "fill",
        source: "parcels",
        paint: {
          "fill-color": [
            "case",
            ["boolean", ["feature-state", "approved"], false],
            "#00ff00",
            [
              "case",
              ["boolean", ["feature-state", "rejected"], false],
              "#ff0000",
              "#f0f0f0",
            ],
          ],
          "fill-opacity": 0.2,
        },
      });
      map.current.addLayer({
        id: "parcelsOutline",
        type: "line",
        source: "parcels",
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "active"], false],
            "#00f",
            "#000",
          ],
          "line-width": 2,
          "line-opacity": 0.5,
        },
      });

      // When a click event occurs on a feature in the states layer,
      // open a popup at the location of the click, with description
      // HTML from the click event's properties.
      map.current.on("click", "parcels", (e) => {
        setCurrent(e.features[0].id);
      });

      // Change the cursor to a pointer when
      // the mouse is over the states layer.
      map.current.on("mouseenter", "parcels", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Change the cursor back to a pointer
      // when it leaves the states layer.
      map.current.on("mouseleave", "parcels", () => {
        map.current.getCanvas().style.cursor = "";
      });
    }
  }
  useEffect(refreshMap, [task, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    map.current.setLayoutProperty(
      "parcels",
      "visibility",
      showParcels ? "visible" : "none",
    );
    map.current.setLayoutProperty(
      "parcelsOutline",
      "visibility",
      showParcels ? "visible" : "none",
    );
  }, [showParcels, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    if (task.latitude === undefined) return;
    parcels.forEach((_, i) => {
      map.current.setFeatureState(
        { source: "parcels", id: i },
        { active: i == current },
      );
    });
  }, [current, mapLoaded]);

  useEffect(() => {
    if (!mapLoaded) return;
    if (task.latitude === undefined) return;
    parcels.forEach((p, i) => {
      const a = getApproval(p);
      map.current.setFeatureState(
        { source: "parcels", id: i },
        { approved: a === true },
      );
      map.current.setFeatureState(
        { source: "parcels", id: i },
        { rejected: a === false },
      );
    });
  }, [task, mapLoaded]);

  const getData = useCallback(async () => {
    get(`/parcels/review/single/?task_id=${taskSelected}`).then((response) => {
      const parcel_set = response.data.parcel_set.map((d) => {
        let user_approval = { id: null };
        d.approval_set.forEach((a) => {
          if (a.approver === user.user.email) {
            user_approval = a;
          }
        });

        return {
          id: d.id,
          approval_set: d.approval_set,
          approval: user_approval,
          data: d.data,
        };
      });
      setTask({ ...response.data, parcel_set });
    });
  }, [taskSelected, user]);

  useEffect(() => {
    getData();
    setCurrent(0);
  }, [getData]);

  const handleAutoNextCheckbox = () => {
    setAutoNext(!autoNext);
  };

  const isEnd = current === parcels.length - 1;
  function previous() {
    if (current !== 0) {
      setCurrent(current - 1);
    }
  }
  function next() {
    if (!isEnd) {
      setCurrent(current + 1);
    }
  }

  const updateApproval = (idx, approve) => {
    postApprovals([{ idx, approve }]);
    if (autoNext) {
      next();
    }
  };
  const approve = (idx) => updateApproval(idx, true);
  const reject = (idx) => updateApproval(idx, false);

  const updateAll = (approve) =>
    postApprovals(
      parcels.map((_, idx) => {
        return { idx, approve };
      }),
    );
  const approveAll = () => updateAll(true);
  const rejectAll = () => updateAll(false);

  function postApprovals(newApprovals) {
    setSubmitting(true);

    const toPost = newApprovals.map((a) => {
      return {
        id: parcels[a.idx].approval.id,
        parcel: parcels[a.idx].id,
        approve: a.approve,
      };
    });
    post("/parcels/approve/", toPost)
      .then(function (response) {
        setTask((t) => {
          const p = t.parcel_set;
          response.data.forEach((r, i) => {
            p[newApprovals[i].idx].approval = r;
            const new_approval_set = p[newApprovals[i].idx].approval_set.map(
              (s) => {
                if (s.approver === user.user.email) {
                  s.approve = r.approve;
                }
                return s;
              },
            );
            p[newApprovals[i].idx].approval_set = new_approval_set;
          });
          return { ...t, parcel_set: p };
        });
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully submitted"),
        });
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      })
      .finally(() => setSubmitting(false));
  }

  return user ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Delineation Submission
                </h1>
              </div>
              <div className="flex">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {new Date(task.created_at).toLocaleString()}
                </span>
              </div>
              <div className="flex">
                <UserIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {task.user?.first_name} {task.user?.last_name}
                </span>
              </div>
              <div className="flex">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {task.user?.email}
                </span>
              </div>
            </div>
            {/*Approval block*/}
            <div className="mt-4 flex flex-col items-center justify-end">
              {/*Statuses */}
              <div className="flex flex-row space-x-4 justify-center">
                <div className="flex">
                  <CheckCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-green-500">{numApproved}</span>
                </div>
                <div className="flex">
                  <XCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-red-500">{numRejected}</span>
                </div>
                <div className="flex">
                  <QuestionMarkCircleIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-gray-500">
                    {parcels.length - numApproved - numRejected}
                  </span>
                </div>
              </div>
              {/* Buttons*/}
              {canReview && (
                <div className="justify-stretch mt-4 flex flex-nowrap flex-row space-x-4 md:flex-col md:space-x-0 md:space-y-2">
                  <button
                    type="button"
                    onClick={approveAll}
                    disabled={isSubmitting}
                    className="inline-flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium bg-white hover:bg-gray-50 text-gray-700 border border-green-500 disabled:opacity-50"
                  >
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-green-800">Approve all</span>
                  </button>
                  <button
                    type="button"
                    onClick={rejectAll}
                    disabled={isSubmitting}
                    className="relative inline-flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium bg-white hover:bg-gray-50 text-gray-700 border border-red-500 disabled:opacity-50"
                  >
                    <XCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                      aria-hidden="true"
                    />
                    <span className="text-red-800">Reject all</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="relative mt-4 ">
            <div
              id="map"
              ref={mapContainer}
              className="map-container aspect-square max-h-[75vh] mx-auto"
            ></div>
            <div
              className="w-full text-center"
              style={{
                position: "absolute",
                top: 0,
                zIndex: 9,
              }}
            >
              <button
                onClick={() => setShowParcels(!showParcels)}
                className="mt-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                {showParcels
                  ? t("common.hideParcels", "Hide Parcels")
                  : t("common.showParcels", "Show Parcels")}
              </button>
            </div>
          </div>
        </div>
        {currentData && (
          <React.Fragment>
            <div className="mt-4 flex flex-wrap items-center justify-between px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1"></div>
              <nav
                className="isolate inline-flex space-x-2 sm:space-x-4 rounded-md"
                aria-label="Pagination"
              >
                <div>
                  <button
                    type="button"
                    onClick={previous}
                    disabled={current === 0 || isSubmitting}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Previous</span>

                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex justify-center">
                  <div
                    className={`relative inline-flex items-center border border-gray-300 rounded-l-md bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:z-20 ${
                      canReview || "rounded-r-md"
                    } ${isSubmitting && "opacity-50"}`}
                  >
                    <span className="sm:hidden">#</span>
                    <span className="hidden sm:inline-block">Record</span>
                    &nbsp;
                    {current + 1} / {parcels.length}
                  </div>
                  {canReview && (
                    <React.Fragment>
                      <button
                        type="button"
                        onClick={() => approve(current)}
                        disabled={isSubmitting}
                        className={`relative inline-flex items-center justify-center border px-4 py-2 text-sm font-medium disabled:opacity-50 ${
                          getApproval(currentData) === true
                            ? "bg-green-100 hover:bg-green-200 border-green-500 "
                            : "bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
                        }`}
                      >
                        <CheckCircleIcon
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => reject(current)}
                        disabled={isSubmitting}
                        className={`relative inline-flex items-center justify-center border rounded-r-md px-4 py-2 text-sm font-medium disabled:opacity-50 ${
                          getApproval(currentData) === false
                            ? "bg-red-100 hover:bg-red-200 border-red-500 "
                            : "bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
                        }`}
                      >
                        <XCircleIcon
                          className="h-5 w-5 flex-shrink-0 text-red-500"
                          aria-hidden="true"
                        />
                      </button>
                    </React.Fragment>
                  )}
                </div>
                <div>
                  <button
                    type="button"
                    onClick={next}
                    disabled={isEnd || isSubmitting}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </nav>
              <div className="-mt-px flex flex-1 justify-end">
                <div className="flex h-5 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={autoNext}
                    onChange={handleAutoNextCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Auto-next
                  </label>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(DelineationReviewSingle);
