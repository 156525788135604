import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { createMap, showTask } from "../utils/map";
import { categories, nameToColor } from "../utils/map.js";

export default function Map({
  map,
  interactive,
  showApproval,
  task,
  parcels,
  draw,
  categoryRef,
  setCurrent,
  mapLoaded,
  setMapLoaded,
  children,
}) {
  const container = useRef(null);

  var drawFeatureID = "";
  var newDrawFeature = false;

  function changeColor(color) {
    if (
      drawFeatureID !== "" &&
      drawFeatureID !== undefined &&
      typeof draw === "object"
    ) {
      draw.setFeatureProperty(drawFeatureID, "portColor", color);
      var feat = draw.get(drawFeatureID);
      draw.add(feat);
    }
  }
  var setDrawFeature = function (e) {
    if (e.features.length && e.features[0].type === "Feature") {
      var feat = e.features[0];
      drawFeatureID = feat.id;
    }
  };

  function initMap() {
    if (container.current === null) return; // need map container to be showing
    if (map.current) return; // initialize map only once
    map.current = createMap(container.current, task);

    if (interactive) {
      if (draw !== null) {
        map.current.addControl(draw);
      }

      map.current.on("draw.create", () => {
        newDrawFeature = true;
        // Setting the mode back to draw immediately causes the current shape
        // not to finish, so wait a bit
        setTimeout(() => draw.changeMode("draw_polygon"), 100);
      });

      map.current.on("draw.update", setDrawFeature);

      map.current.on("draw.selectionchange", setDrawFeature);

      map.current.on("click", function (e) {
        if (!newDrawFeature) {
          var drawFeatureAtPoint = draw.getFeatureIdsAt(e.point);

          //if another drawFeature is not found - reset drawFeatureID
          drawFeatureID = drawFeatureAtPoint.length
            ? drawFeatureAtPoint[0]
            : "";
        }
        changeColor(categories[categoryRef.current]?.color);

        newDrawFeature = false;
      });
      map.current.on("touchend", function (e) {
        // Need a small timeout because for touch event we need to draw.create to complete
        // resetting newDrawFeature = true first
        setTimeout(() => {
          if (!newDrawFeature) {
            var drawFeatureAtPoint = draw.getFeatureIdsAt(e.point);
            //if another drawFeature is not found - reset drawFeatureID
            // Touch event seems to have a different ordering of the drawFeatureAtPoint
            drawFeatureID = drawFeatureAtPoint.length
              ? drawFeatureAtPoint[drawFeatureAtPoint.length - 1]
              : "";
          }
          changeColor(categories[categoryRef.current]?.color);

          newDrawFeature = false;
        }, 100);
      });
    } else {
      // When a click event occurs on a feature in the states layer,
      // open a popup at the location of the click, with description
      // HTML from the click event's properties.
      map.current.on("click", "parcels", (e) => {
        setCurrent(e.features[0].id);
      });

      // Change the cursor to a pointer when
      // the mouse is over the states layer.
      map.current.on("mouseenter", "parcels", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Change the cursor back to a pointer
      // when it leaves the states layer.
      map.current.on("mouseleave", "parcels", () => {
        map.current.getCanvas().style.cursor = "";
      });
    }
    map.current.on("load", () => {
      // Dummy layer for tracking the end of the raster layers
      const layers = map.current.getStyle().layers;
      let beforeLayer;
      for (let i = 0; i < layers.length - 1; i++) {
        if (layers[i].id == "satellite") {
          beforeLayer = layers[i + 1].id;
        }
      }
      map.current.addSource("empty", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
      map.current.addLayer(
        {
          id: "after-satellite",
          type: "symbol",
          source: "empty",
        },
        beforeLayer,
      );
      setMapLoaded(true);
    });
  }

  useEffect(() => {
    initMap();
  }, []);

  function refreshTask() {
    if (!mapLoaded) return;
    if (!task) return;

    showTask(map.current, task);

    if (interactive) return;

    const newData = {
      type: "FeatureCollection",
      features: parcels.map((p) => p.data),
    };

    const geojsonSource = map.current.getSource("parcels");
    if (geojsonSource !== undefined) {
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(newData);
    } else {
      // Create GeoJSON source
      map.current.addSource("parcels", {
        type: "geojson",
        data: newData,
        generateId: true,
      });

      Promise.all([
        map.current.loadImage("../../../check.png", (error, image) => {
          if (error) throw error;

          map.current.addImage("check-icon", image);

          map.current.addLayer({
            id: "parcelsApprove",
            type: "symbol",
            source: "parcels",
            layout: {
              "icon-image": "check-icon",
              "icon-size": 1,
              "icon-anchor": "center",
              "icon-allow-overlap": true,
            },
            paint: {
              "icon-color": "green",
              "icon-opacity": [
                "case",
                ["boolean", ["feature-state", "approved"], true],
                1,
                [
                  "case",
                  ["boolean", ["feature-state", "rejected"], true],
                  0,
                  0,
                ],
              ],
            },
          });

          // Set timeout to make the layer visible to hack around bug where it
          // sometimes starts invisible (since we haven't figured out why this
          // happens)
          setTimeout(() => {
            map.current.setLayoutProperty(
              "parcelsApprove",
              "visibility",
              "visible",
            );
          }, 100);
        }),
        map.current.loadImage("../../../cross.png", (error, image) => {
          if (error) throw error;

          map.current.addImage("cross-icon", image);

          map.current.addLayer({
            id: "parcelsReject",
            type: "symbol",
            source: "parcels",
            layout: {
              "icon-image": "cross-icon",
              "icon-size": 1,
              "icon-anchor": "center",
              "icon-allow-overlap": true,
            },
            paint: {
              "icon-color": "red",
              "icon-opacity": [
                "case",
                ["boolean", ["feature-state", "approved"], true],
                0,
                [
                  "case",
                  ["boolean", ["feature-state", "rejected"], true],
                  1,
                  0,
                ],
              ],
            },
          });

          // Set timeout to make the layer visible to hack around bug where it
          // sometimes starts invisible (since we haven't figured out why this
          // happens)
          setTimeout(() => {
            map.current.setLayoutProperty(
              "parcelsReject",
              "visibility",
              "visible",
            );
          }, 100);
        }),
      ]);
      map.current.addLayer({
        id: "parcels",
        type: "fill",
        source: "parcels",
        paint: {
          "fill-color": ["get", "portColor"],
          "fill-opacity": 0.2,
        },
      });
      map.current.addLayer({
        id: "parcelsOutline",
        type: "line",
        source: "parcels",
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "active"], false],
            "#33EFFF",
            "#000",
          ],
          "line-width": 2,
          "line-opacity": 0.8,
        },
      });
    }
  }

  useEffect(() => {
    refreshTask();
  }, [task, mapLoaded]);

  return (
    <div
      ref={container}
      className="map-container aspect-square max-h-[75vh] w-full max-w-xl"
    >
      {children}
    </div>
  );
}
