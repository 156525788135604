import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";

import { categories, nameToColor } from "../utils/map.js";

const createMap = (container, task, interactive) => {
  return new mapboxgl.Map({
    container: container,
    style: "mapbox://styles/mapbox/satellite-v9", // style URL
    interactive: interactive,
    bounds: task.bounds || null,
    maxBounds: task.bounds || null,
  });
};

const showTask = (map, task) => {
  // Allow map to show new point
  map.setMinZoom(0);
  map.setMaxZoom(24);
  map.setMaxBounds(null);
  // Move the map to the central point
  map.fitBounds(task.bounds, { duration: 0 });
  // Restrict map to current viewport
  map.setMinZoom(map.getZoom());
  map.setMaxZoom(map.getZoom());
  map.setMaxBounds(map.getBounds());
};

export default function Map({
  map,
  interactive,
  task,
  categoryRef,
  initialLabels,
}) {
  const container = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  function initMap() {
    if (container.current === null) return; // need map container to be showing
    if (map.current) return; // initialize map only once
    map.current = createMap(container.current, task, interactive);

    map.current.on("load", () => {
      setMapLoaded(true);
    });
  }

  useEffect(() => {
    initMap();
  });

  function setColor(f, color) {
    // Default to setting active palette color if not supplied
    color = color || categories[categoryRef.current]?.color;
    map.current.setFeatureState({ source: "grid", id: f.id }, { color });
  }

  function refreshTask() {
    if (!mapLoaded) return;
    if (!task) return;

    showTask(map.current, task);

    const gridSource = map.current.getSource("grid");
    if (gridSource !== undefined) {
      gridSource.setData(task.grid);
      map.current.removeFeatureState({ source: "grid" });
    } else {
      // Create GeoJSON source
      map.current.addSource("grid", {
        type: "geojson",
        data: task.grid,
      });

      map.current.addLayer({
        id: "grid",
        type: "fill",
        source: "grid",
        paint: {
          "fill-color": ["coalesce", ["feature-state", "color"], "#fff"],
          "fill-opacity": [
            "case",
            ["boolean", ["to-boolean", ["feature-state", "color"]], true],
            0.2,
            0.0,
          ],
        },
      });
      map.current.addLayer({
        id: "gridLines",
        type: "line",
        source: "grid",
        paint: {
          "line-width": 2,
          "line-color": "#ffffff",
          "line-opacity": 0.2,
        },
      });

      if (interactive) {
        // When a click event occurs on a feature in the grid layer,
        // set the color to the current color
        map.current.on("click", "grid", (e) => setColor(e.features[0]));

        // When the grid is dragged through, set all points covered during the
        // drag to the current color
        map.current.on("drag", "grid", (e) => {
          const canvas = map.current.getCanvasContainer();
          // Map event-relative mouse position to map-relative coordinates
          const rect = canvas.getBoundingClientRect();
          const pos = new mapboxgl.Point(
            e.originalEvent.clientX - rect.left - canvas.clientLeft,
            e.originalEvent.clientY - rect.top - canvas.clientTop,
          );
          // Get features that intersect with the current mouse position
          const features = map.current.queryRenderedFeatures(pos);
          if (features.length > 0) {
            setColor(features[0]);
          }
        });

        // Change the cursor to a pointer when the mouse is over the grid layer.
        map.current.on("mouseenter", "grid", () => {
          map.current.getCanvas().style.cursor = "pointer";
        });

        // Change the cursor back to a pointer when it leaves the grid layer.
        map.current.on("mouseleave", "grid", () => {
          map.current.getCanvas().style.cursor = "";
        });
      } else {
        // Disable cursor on non-interactive map.
        map.current.on("mouseover", () => {
          map.current.getCanvas().style.cursor = "not-allowed";
        });
      }
    }

    if (initialLabels) {
      initialLabels.forEach((label, i) => {
        setColor(task.grid.features[i], nameToColor[label]);
      });
    }
  }

  useEffect(() => {
    refreshTask();
  }, [task, mapLoaded]);

  return (
    <div
      ref={container}
      className="map-container aspect-square max-h-[75vh] w-full"
    ></div>
  );
}
