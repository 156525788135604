import { getToken } from "../../user/utils/user";

const axios = require("axios").default;

export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

function makeConfig(token) {
  token = token || getToken();
  return token
    ? {
        headers: {
          Authorization: "Token " + token,
        },
      }
    : {};
}

export function post(url, data, token) {
  return axios.post(BACKEND_URL + url, data, makeConfig(token));
}
export function put(url, data, token) {
  return axios.put(BACKEND_URL + url, data, makeConfig(token));
}
export function patch(url, data, token) {
  return axios.patch(BACKEND_URL + url, data, makeConfig(token));
}

export function get(url, token) {
  return axios.get(BACKEND_URL + url, makeConfig(token));
}

export function unwrapError(e, form) {
  if (e && e.code === "ERR_NETWORK") {
    const r = "No internet connection";
    return form ? { non_field_errors: r } : r;
  } else if (e && e.code === "ERR_BAD_REQUEST" && e.response.status !== 400) {
    return form
      ? e.response.data
      : e.message + "\n\n" + JSON.stringify(e.response.data, null, 2);
  } else {
    let data = e.response.data;
    return form ? data : JSON.stringify(data);
  }
}
