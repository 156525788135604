import { Field } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";

function PasswordInput({ errorData, isConfirm, t }) {
  let elementName = isConfirm ? "password2" : "password";
  return (
    <div>
      <label
        htmlFor={elementName}
        className="block text-sm font-semibold leading-6 text-gray-900"
      >
        {isConfirm
          ? t("user.confirmPassword", "Confirm Password")
          : t("user.password", "Password")}
      </label>
      <Field
        id={isConfirm ? "password2" : "password"}
        data-testid={isConfirm ? "password2" : "password"}
        type="password"
        name={elementName}
        className="mt-1 appearance-none text-cyan-900 bg-white border-gray-300 rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-cyan-400 focus:ring-2 focus:ring-cyan-500"
      />
      <span className="error text-red-700 text-xs">
        {(errorData && errorData[elementName]) || "\u00A0"}
      </span>
    </div>
  );
}

export default withTranslation()(PasswordInput);
