import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGFpc3kxMjMyMSIsImEiOiJjbGhhejhjNW8wbWduM2hvaThvOTgzamdpIn0.sLKvL0-cxWSUp_8awXxiJg";

export const categories = [
  { name: "unknown", label: "Unknown", color: null },
  { name: "built", label: "Built", color: "#ef4444" },
  { name: "water", label: "Water", color: "#3b82f6" },
  { name: "trees", label: "Trees", color: "#22c55e" },
  { name: "nontrees", label: "Non-trees", color: "#eab308" },
];
export const colorToName = Object.fromEntries(
  Object.entries(categories).map(([_, c]) => [c.color, c.name]),
);
export const nameToColor = Object.fromEntries(
  Object.entries(categories).map(([_, c]) => [c.name, c.color]),
);

export function extractLabels(map, features) {
  return features.map((f) => {
    const fs = map.current.getFeatureState({
      source: "grid",
      id: f.id,
    });
    return { feature: f, label: colorToName[fs.color || null] };
  });
}
