export const MIN_ACCURACY = 15;
export const MIN_DISTANCE = 30;

export function getCurrentPosition(showAlert, t, success, error, options) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      success,
      () => {
        showAlert({
          type: "warning",
          message: t(
            "plot.cannotGetCurrentPosition",
            "Cannot get current position",
          ),
        });
        error();
      },
      {
        enableHighAccuracy: true,
        ...options,
      },
    );
  } else {
    showAlert({
      type: "warning",

      message: t(
        "plot.browserDoesNotSupportGeolocation",
        "Browser doesn't support Geolocation",
      ),
    });
    error();
  }
}

export function watchPosition(showAlert, t, success, error, options) {
  if (navigator.geolocation) {
    return navigator.geolocation.watchPosition(
      success,
      () => {
        showAlert({
          type: "warning",
          message: t(
            "plot.cannotGetCurrentPosition",
            "Cannot get current position",
          ),
        });
        error();
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        ...options,
      },
    );
  } else {
    showAlert({
      type: "warning",

      message: t(
        "plot.browserDoesNotSupportGeolocation",
        "Browser doesn't support Geolocation",
      ),
    });
    error();
  }
}

export function clearWatch(id) {
  navigator.geolocation.clearWatch(id);
}

export function haversineDistance(lat1deg, lon1deg, lat2deg, lon2deg) {
  function toRad(x) {
    return (x * Math.PI) / 180.0;
  }
  const lat1 = toRad(lat1deg);
  const lat2 = toRad(lat2deg);
  const lon1 = toRad(lon1deg);
  const lon2 = toRad(lon2deg);

  function hav(x) {
    const v = Math.sin(x / 2);
    return v * v;
  }

  const ht =
    hav(lat2 - lat1) + Math.cos(lat1) * Math.cos(lat2) * hav(lon2 - lon1);

  // equatorial mean radius of Earth (in meters)
  const R = 6378137;
  return 2 * R * Math.asin(Math.sqrt(ht));
}

// Parse a `lat,lon` string into components
// Complication: the lat and lon might be in a user locale that uses comma for
//               the decimal separator, so we need to handle strings like
//               `1.234,1.234` and `1,234,1,234`
export function parseManualLocation(value) {
  let components = value.split(",");
  // If there are 4 components (3 commas), rewrite components 1/2 and 3/4 as
  // numbers with `.` as the decimal separator
  if (components.length === 4) {
    components = [
      components[0] + "." + components[1],
      components[2] + "." + components[3],
    ];
  }
  // Make sure we can parse out the two numbers
  if (components.length === 2) {
    const c1 = parseFloat(components[0]);
    const c2 = parseFloat(components[1]);
    if (!isNaN(c1) && !isNaN(c2)) {
      return [c1, c2];
    }
  }

  return undefined;
}

export function getDistance(data, value) {
  if (!data || !Array.isArray(data) || data?.length === 0) {
    return "-";
  }
  const distances = [];
  // Check distance from currentLocation to all points in data
  for (const point of data) {
    distances.push(
      haversineDistance(
        value.latitude,
        value.longitude,
        point.latitude,
        point.longitude,
      ),
    );
  }
  return Math.min(...distances).toFixed();
}
