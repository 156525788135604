import LandcoverReviewSingle from "./pages/ReviewSingle";
import LandcoverReviewSummary from "./pages/ReviewSummary";
import SuperuserSingle from "./pages/SuperuserSingle";
import SuperuserSummary from "./pages/SuperuserSummary";
import LandcoverTask from "./pages/Task";

const routes = [
  { path: "task", element: LandcoverTask },
  { path: "review", element: LandcoverReviewSummary },
  { path: "review/single", element: LandcoverReviewSingle },
  { path: "superuser", element: SuperuserSummary },
  { path: "superuser/single", element: SuperuserSingle },
];
export default routes;
