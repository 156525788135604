import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function List({ content, errors, onDelete, t }) {
  return (
    <>
      <Tooltip id="list-tooltip" className="z-50" />
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("plot.name", "Name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("plot.latitude", "Latitude")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("plot.longitude", "Longitude")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("crop.crop", "Crop")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("plot.dateRecorded", "Date Recorded")}
                    </th>
                    <th scope="col" className="relative w-4">
                      <span className="sr-only">
                        {t("plot.status", "Status")}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">{t("plot.edit", "Edit")}</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">
                        {t("common.delete", "Delete")}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {content.map((c) => (
                    <tr key={c.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {c.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {c.latitude}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {c.longitude}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {t(`crop.${c.crops[0].crop}`, c.crops[0].crop)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {c.createddate &&
                          new Date(c.createddate).toLocaleString()}
                      </td>
                      <td className="whitespace-nowrap w-6 text-sm text-gray-500">
                        {errors[c.id] && (
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-yellow-400 align-middle"
                            data-tooltip-id="list-tooltip"
                            data-tooltip-content={errors[c.id]}
                            data-tooltip-place="bottom"
                          />
                        )}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link
                          to="/data/form/"
                          state={{ id: c.id }}
                          className="text-cyan-600 hover:text-cyan-900"
                        >
                          {t("plot.edit", "Edit")}
                          <span className="sr-only">, {c.name}</span>
                        </Link>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          onClick={() => onDelete(c)}
                          className="text-red-600 hover:text-red-900"
                        >
                          {t("common.delete", "Delete")}
                          <span className="sr-only">, {c.name}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(List);
