import { Dialog, Transition } from "@headlessui/react";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import {
  browserVersion,
  isAndroid,
  isFirefox,
  isIOS,
  isMobile,
  isOpera,
} from "mobile-device-detect";
import { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { usePWAInstall } from "react-use-pwa-install";

import { getStandalone } from "../utils/helpers";

const platforms = {
  NATIVE: "native", // currently: Chrome, Edge mobile, Samsung internet
  FIREFOX: "firefox",
  FIREFOX_NEW: "firefox_new", // above version 79
  OPERA: "opera",
  IDEVICE: "idevice",
  OTHER: "other", // don't know, so will do nothing
};

function InstallPrompt({ t }) {
  const install = usePWAInstall();
  const [open, setOpen] = useState(false);
  const log = false;

  useEffect(() => {
    if (install) {
      setOpen(true);
    } else {
      if (supported(log) && !isInstalled(log)) {
        setOpen(true);
      }
    }
  }, [install, log]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ArrowDownCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("common.installApplication", "Install Application")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t(
                          "common.wouldYouLikeToInstall",
                          "Would you like to install the application for offline access? ",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {install ? (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setOpen(false);
                        install();
                      }}
                    >
                      {t("common.install", "Install")}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      {t("common.cancel", "Cancel")}
                    </button>
                  </div>
                ) : (
                  installationInstructions()
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function getPlatform() {
  let platform = platforms.OTHER;
  if (window.hasOwnProperty("BeforeInstallPromptEvent")) {
    platform = platforms.NATIVE;
  } else if (isMobile && isAndroid && isFirefox && +browserVersion >= 79) {
    platform = platforms.FIREFOX_NEW;
  } else if (isMobile && isAndroid && isFirefox) {
    platform = platforms.FIREFOX;
  } else if (isOpera && isAndroid && isMobile) {
    platform = platforms.OPERA;
  } else if (isIOS && isMobile) {
    platform = platforms.IDEVICE;
  }

  return platform;
}

export function isInstalled(log) {
  if (
    window.navigator.standalone === true ||
    window.matchMedia("(display-mode: standalone)").matches ||
    getStandalone()
  ) {
    log && console.log("isInstalled: true. Already in standalone mode");
    return true;
  }
  log && console.log("isInstalled: false.");
  return false;
}

function supported(log) {
  const platform = getPlatform();
  if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
    log && console.log("supported: true - manual support");
    return true;
  }
  log && console.log("supported: false");
  return false;
}

function installationInstructions() {
  const platform = getPlatform();
  if (platform === platforms.IDEVICE) {
    return (
      <div className="mt-5 text-sm text-gray-500 text-center">
        <p>Tap the share button:</p>
        <IOSShareIcon className="h-8 w-8 mx-auto" />
        <p>then find and tap 'Add to Home Screen'</p>
      </div>
    );
  } else if (platform === platforms.FIREFOX) {
    return (
      <div className="mt-5 text-sm text-gray-500 text-center">
        <p>Tap this icon on the address bar:</p>
        <FireFoxA2HSIcon className="h-8 w-8 mx-auto" />
        <p>then tap '+Add to Homescreen'</p>
      </div>
    );
  } else if (platform === platforms.FIREFOX_NEW) {
    return (
      <div className="mt-5 text-sm text-gray-500 text-center">
        <p>Tap the menu button:</p>
        <MenuIcon className="h-8 w-8 mx-auto" />
        <p>then tap 'Install'</p>
      </div>
    );
  } else if (platform === platforms.OPERA) {
    return (
      <div className="mt-5 text-sm text-gray-500 text-center">
        <p>Tap the menu button:</p>
        <MenuIcon className="h-8 w-8 mx-auto" />
        <p>
          then tap &nbsp;'
          <OperaA2HSIcon className="h-4 w-4 inline-block align-text-top" />
          Home screen'
        </p>
      </div>
    );
  } else if (platform === platforms.OTHER) {
    return (
      <div className="mt-5 text-sm text-red-500 text-center">
        Unfortunately the install feature is not supported by your browser.
      </div>
    );
  }
}

function IOSShareIcon(props) {
  return (
    <div {...props}>
      <svg viewBox="0 0 50 50">
        <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
        <path d="M24 7h2v21h-2z" />
        <path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
      </svg>
    </div>
  );
}

function FireFoxA2HSIcon(props) {
  return (
    <div {...props}>
      <svg viewBox="0 0 700.000000 700.000000">
        <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)">
          <path
            d="M3341 6328 c-29 -8 -266 -235 -614 -585 -4 -5 -149 -150 -323 -323
-173 -173 -323 -323 -332 -332 -9 -9 -156 -157 -327 -327 -170 -170 -316 -316
-323 -323 -7 -7 -140 -141 -295 -298 -322 -325 -367 -382 -374 -474 -5 -64 -3
-70 62 -170 36 -56 66 -68 178 -69 153 -2 160 1 274 113 56 54 105 105 110
112 4 8 34 29 67 47 99 55 91 141 93 -932 3 -1047 6 -1108 61 -1194 17 -26 37
-64 45 -83 8 -19 30 -54 50 -78 20 -23 38 -50 42 -60 6 -17 42 -46 133 -109
26 -18 60 -42 76 -54 15 -12 56 -30 90 -40 33 -9 83 -27 110 -39 34 -15 83
-25 160 -32 63 -5 599 -7 1251 -6 1029 3 1145 5 1190 20 28 9 79 26 115 38 66
22 86 31 180 85 71 41 226 198 241 244 7 20 25 51 40 69 29 32 32 42 44 117 4
22 16 69 28 105 22 64 22 73 27 1000 3 514 7 936 8 937 1 2 13 10 26 18 35 23
47 14 184 -126 64 -67 128 -127 142 -134 35 -17 153 -26 228 -17 58 8 67 12
103 52 23 24 45 52 50 61 12 23 12 201 -1 233 -5 14 -577 594 -1271 1288
-1233 1232 -1264 1262 -1308 1269 -64 11 -199 9 -240 -3z m167 -632 c33 -18
1354 -1341 1373 -1376 12 -21 15 -228 17 -1189 2 -660 -1 -1199 -6 -1246 -11
-93 -20 -110 -109 -201 -34 -35 -65 -56 -91 -64 -28 -7 -405 -10 -1246 -8
l-1205 3 -28 23 c-15 12 -42 32 -60 45 -21 14 -47 51 -74 103 l-41 82 1 1202
1 1203 28 45 c15 25 329 348 697 718 493 496 676 673 693 674 13 0 35 -6 50
-14z"
          ></path>
          <path
            d="M3403 4280 c-66 -40 -67 -49 -73 -421 -4 -257 -8 -340 -18 -352 -25
-29 -108 -37 -401 -37 l-289 0 -48 -32 c-31 -22 -57 -49 -72 -80 -21 -41 -23
-51 -12 -82 17 -51 68 -102 115 -115 22 -5 174 -12 337 -15 240 -4 303 -8 327
-21 61 -31 61 -31 61 -355 1 -162 5 -314 9 -338 14 -72 85 -132 157 -132 29 0
46 9 83 42 25 23 51 53 58 67 9 19 12 119 13 348 l0 323 29 32 29 33 314 0
313 1 60 29 c57 28 61 33 72 79 14 57 5 116 -24 148 -10 11 -36 31 -59 44 -41
24 -43 24 -368 27 l-328 2 -19 24 c-18 22 -19 43 -19 336 0 172 -3 326 -6 342
-3 16 -25 50 -49 76 -42 46 -44 47 -101 47 -41 0 -69 -6 -91 -20z"
          ></path>
        </g>
      </svg>
    </div>
  );
}

function MenuIcon(props) {
  return (
    <div {...props}>
      <svg viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-2 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
          fill="context-fill"
          fill-opacity=".8"
        ></path>
      </svg>
    </div>
  );
}

function OperaA2HSIcon(props) {
  return (
    <div {...props}>
      <svg viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4zm2 .51A.51.51 0 0 1 7.51 4h8.98a.51.51 0 0 1 .51.51v13.98a.51.51 0 0 1-.51.51H7.51a.51.51 0 0 1-.51-.51V4.51zM10.5 20a.5.5 0 1 0 0 1h3a.5.5 0 1 0 0-1h-3z"
          fill="context-fill"
          fill-opacity=".8"
        ></path>
      </svg>
    </div>
  );
}

export default withTranslation()(InstallPrompt);
