import { Field } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";

function UserInput({ errorData, t }) {
  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-semibold leading-6 text-gray-900"
      >
        {t("user.emailAddress", "Email address")}
      </label>
      <Field
        type="email"
        name="email"
        id="email"
        className="mt-1 appearance-none text-cyan-900 border-gray-300 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-cyan-400 focus:ring-2 focus:ring-cyan-500"
      />

      <span className="error text-red-700 text-xs">
        {(errorData && (errorData.email || errorData.username)) || "\u00A0"}
      </span>
    </div>
  );
}

export default withTranslation()(UserInput);
