import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { questionCodeMapper } from "../pages/ReviewDetail";

function Question({
  questionCode,
  currentAnswer,
  onClick,
  isSubmitting,
  children,
}) {
  return (
    <div className="flex flex-row gap-4">
      <div className="flex self-center">
        <button
          type="button"
          data-testid={`${questionCode}-question-yes`}
          onClick={() => onClick(0, questionCode)}
          className={`relative inline-flex items-center justify-center border rounded-l-md px-4 py-2 text-sm font-medium disabled:opacity-50 ${
            currentAnswer === 0
              ? "bg-green-100 hover:bg-green-200 border-green-500 "
              : "bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
          }`}
        >
          <CheckCircleIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          data-testid={`${questionCode}-question-no`}
          onClick={() => onClick(1, questionCode)}
          className={`relative inline-flex items-center justify-center border rounded-r-md px-4 py-2 text-sm font-medium disabled:opacity-50 ${
            currentAnswer === 1
              ? "bg-red-100 hover:bg-red-200 border-red-500 "
              : "bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
          }`}
        >
          <XCircleIcon
            className="h-5 w-5 flex-shrink-0 text-red-500"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="flex self-center">
        <div>
          <div className="mr-4">{questionCodeMapper[questionCode % 1000]}</div>
          {children}
        </div>
      </div>
    </div>
  );
}
export default Question;
