import AnswerDetail from "./pages/AnswerDetail";
import DataForm from "./pages/Form";
import ReviewDetail from "./pages/ReviewDetail";
import ReviewQueue from "./pages/ReviewQueue";
import DataReviewSingle from "./pages/ReviewSingle";
import DataReviewSummary from "./pages/ReviewSummary";
import DataReviewedParcelSingle from "./pages/ReviewedParcelDetail";
import DataSummaryAgentSupervisor from "./pages/SummaryAgentSupervisor";
import DataReviewerSummary from "./pages/SummaryReviewer";
import DataReviewerSummaryOld from "./pages/SummaryReviewerOld";
import DataSummarySuperuser from "./pages/SummarySuperuser";
import DataSummarySupervisor from "./pages/SummarySupervisor";
import DataTask from "./pages/Task";
import DataUserReview from "./pages/UserReview";

const routes = [
  { path: "task", element: DataTask, openAccess: true },
  { path: "superuser", element: DataSummarySuperuser },
  { path: "review/single", element: DataReviewSingle },
  { path: "review/user", element: DataUserReview },
  { path: "review", element: DataReviewSummary },
  { path: "review/activity", element: DataReviewerSummaryOld },
  { path: "review/queue", element: ReviewQueue },
  { path: "review/detail", element: ReviewDetail },
  { path: "summary", element: DataSummarySupervisor },
  { path: "reviewer", element: DataReviewerSummary },
  { path: "reviewer/answer", element: AnswerDetail },
  { path: "summary-as", element: DataSummaryAgentSupervisor },
  { path: "review/parcel", element: DataReviewedParcelSingle },
  { path: "form", element: DataForm, openAccess: true },
];
export default routes;
