import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Circle,
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import { get } from "../../common/utils/api";
import ImageGeolocationMarkers from "../components/ImageGeolocations";
import ReviewQuestions from "../components/ReviewQuestions.js";
import { MIN_ACCURACY } from "../utils/location.js";

export const L = require("leaflet");
export var blueIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
export const questionCategory = {
  1: "location",
  2: "location",
  3: "crop",
  4: "crop",
  5: "crop",
  6: "crop",
  7: "crop",
  8: "crop",
  9: "disease",
  10: "plot",
  11: "weight_value",
  12: "harvested",
  13: "name_value",
  14: "crop",
};
export const questionCodeMapper = {
  1: "Is the GPS coordinate 10m deep inside the parcel?",
  2: "Is the parcel at least 30m wide? When the farmers organize their field in small adjacent lots that are bearing the same crop, is the group of lots 30m wide?",
  3: "Does the picture give a view of the parcel?",
  4: "Is the crop reported visible on the picture?",
  5: "Does the crop reported cover the entire parcel?",
  6: "If the crop is visible, if it covers the entire parcel, and if it is a major crop (not a tree crop), is the phenological stage correct for a majority of the plants on the picture?",
  7: "If it is a tree crop, is the tree less than 1 year?",
  8: "Is there another crop that is not reported present on the picture?",
  9: "If disease or deficiency is correctly identified?",
  10: "Is the square tilted?",
  11: "Is each of the values recoded corresponding to the value entered?",
  12: "Is the subplot harvested?",
  13: "If drying is involved, does the name of the parcel correspond to the name on the bag next to the drying crop?",
  14: "If there no evidence of disease or deficiency?",
};

var redIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const violetIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [10, 20],
  iconAnchor: [8, 20],
  popupAnchor: [1, -50],
  shadowSize: [20, 20],
});

export const API_KEY = "AIzaSyCddB8v9ft_ATozIdkN23KBI4SPn-tyuBM";

export const CustomMarker = ({ active, id, defaultColor, f, setCurrent }) => {
  const map = useMap();
  const circleRefs = useRef();
  useEffect(() => {
    if (circleRefs.current) {
      circleRefs.current.property = { id: id };
      circleRefs.current
        .addTo(map)
        .on("click", (e) => setCurrent(e.target.property.id));
    }
  }, [id, map, setCurrent]);

  useEffect(() => {
    if (circleRefs.current) {
      circleRefs.current.setStyle({
        // color: color,
        fillOpacity: active ? 0.05 : 0,
      });
    }
  }, [map, active]);

  if (f.latitude && f.longitude) {
    return (
      <Circle
        center={{ lat: f.latitude, lng: f.longitude }}
        ref={(r) => {
          circleRefs.current = r;
        }}
        radius={f.manualLocation ? 5 : f.accuracy}
        pathOptions={f.manualLocation ? { dashArray: "4" } : {}}
      >
        {active && (
          <Marker
            position={{ lat: f.latitude, lng: f.longitude }}
            icon={f.lowAccuracy ? redIcon : blueIcon}
            autoPan={false}
          />
        )}
        <CircleMarker
          center={{ lat: f.latitude, lng: f.longitude }}
          color={defaultColor}
          fillOpacity={0}
          radius={1}
        />
      </Circle>
    );
  } else {
    return null;
  }
};

export function ChangeView({ markers, current }) {
  const map = useMap();

  useEffect(() => {
    let markerBounds = latLngBounds([]);
    if (markers && markers.length > 0) {
      // Show all markers on first render
      markers.forEach((marker) => {
        if (marker.latitude && marker.longitude) {
          markerBounds.extend([marker.latitude, marker.longitude]);
        }
      });
      Object.keys(markerBounds).length > 0 && map.fitBounds(markerBounds);
    }
  }, [markers, map]);

  useEffect(() => {
    if (markers && markers.length > 0) {
      // For subsquent ones, zoom in unto the specific marker
      let marker = markers[current];
      if (marker.latitude && marker.longitude) {
        map.panTo([marker.latitude, marker.longitude]);
      }
    }
  }, [markers, current, map]);
  return null;
}

function ReviewDetail({ showAlert, t }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const [remainingQuestions, setRemainingQuestions] = useState({});
  const canReview = user.user.is_reviewer;

  let [allData, setAllData] = useState([]);
  let [remainingList, setRemainingList] = useState([]);
  let [remainingListIndex, setRemainingListIndex] = useState(0);
  const [showRemaining, setShowRemaining] = useState(true);
  let [current, setCurrent] = useState(0);
  const [currentData, setCurrentData] = useState(null);
  useEffect(() => {
    if (allData && allData.length > 0 && current < allData.length) {
      setCurrentData(allData[current]);
    } else {
      setCurrentData(null); // or some default value
    }
  }, [allData, current]);
  const [searchParams] = useSearchParams();
  const userSelected = searchParams.get("user");
  const dateSelected = searchParams.get("date");
  let [polyline, setPolyline] = useState([]);
  let [position, setPosition] = useState([6.0, -3.8]);

  let [useSatellite, setUseSatellite] = useState(false);
  const defaultColor = useSatellite ? "#fafafa" : "gray";
  const pathOptions = { color: defaultColor };

  const [autoNext, setAutoNext] = useState(true);
  const [showLine, setShowLine] = useState(true);

  const [isSubmitting, setSubmitting] = useState(false);

  const getData = useCallback(async () => {
    get(
      `/data/review/detail?username=${userSelected}&date=${dateSelected}`,
    ).then((response) => {
      const data = response.data.map((d) => {
        return {
          createddate: new Date(d.form_created_at),
          user: d.user__first_name + " " + d.user__last_name,
          id: d.id,
          irrigation: d.data.irrigation,
          latitude: d.data.latitude,
          longitude: d.data.longitude,
          questions: d.questions,
          // Fallback for datapoints collected before accuracy was tracked
          accuracy: d.data.accuracy || 0,

          manualLocation: d.data.manualLocation,
          lowAccuracy: d.data.manualLocation || d.data.accuracy > MIN_ACCURACY,
          name: d.data.name,
          crops: d.data.crops,
        };
      });
      setAllData(data);

      let tempRemainingDict = {};
      data.forEach((currData, index) => {
        const currentId = index;
        currData.questions.forEach((question) => {
          if (question.answer_code === null) {
            tempRemainingDict[currentId] =
              (tempRemainingDict[currentId] || 0) + 1;
          }
        });
      });
      // create a dictionary to track the number of questions unanswered for each dataID for current reviewer
      setRemainingQuestions(tempRemainingDict);
      const remainingList = Object.keys(tempRemainingDict).map(Number);
      setRemainingList(remainingList);
      if (remainingList.length === 0) {
        navigate("/data/review/queue");
      } else {
        setCurrent(remainingList[remainingListIndex]);
      }

      const tmpPolyline = [];
      data.forEach((d) => {
        if (d.latitude && d.longitude) {
          tmpPolyline.push([d.latitude, d.longitude]);
        }
      });
      setPolyline(tmpPolyline);
      setPosition([data[0].latitude, data[0].longitude]);
    });
  }, [userSelected, dateSelected]);

  useEffect(() => {
    getData();
    setCurrent(0);
  }, [getData]);

  const handleAutoNextCheckbox = () => {
    setAutoNext(!autoNext);
  };
  const handleShowRemainingCheckBox = () => {
    // check if any data is left to review
    if (!showRemaining) {
      if (remainingList.length > 0) {
        setCurrent(remainingList[0]);
        setRemainingListIndex(0);
      }
    }
    setShowRemaining(!showRemaining);
  };
  const handleShowLineCheckbox = () => {
    setShowLine(!showLine);
  };

  const isEnd = current === allData.length - 1;
  function previous() {
    if (showRemaining) {
      setCurrent(remainingList[remainingListIndex - 1]);
      setRemainingListIndex(remainingListIndex - 1);
    } else {
      setCurrent(current - 1);
    }

    setPosition([allData[current].latitude, allData[current].longitude]);
  }
  function next() {
    if (showRemaining) {
      if (remainingQuestions[current] === 0) {
        setCurrent(remainingList[remainingListIndex]);
        setRemainingListIndex(remainingListIndex);
      } else {
        setCurrent(remainingList[remainingListIndex + 1]);
        setRemainingListIndex(remainingListIndex + 1);
      }
    } else {
      setCurrent(current + 1);
    }
    setPosition([allData[current].latitude, allData[current].longitude]);
  }

  return user ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-start md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-start">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Review Submissions
                </h1>
              </div>
              <div className="md:flex space-x-2">
                <div className="flex">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-gray-500" data-testid="date">
                    {dateSelected}
                  </span>
                </div>
                <div className="flex">
                  <UserIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span
                    className="text-sm text-gray-500"
                    data-testid="userName"
                  >
                    {currentData?.user}
                  </span>
                </div>
                <div className="flex">
                  <EnvelopeIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm text-gray-500" data-testid="email">
                    {userSelected}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <MapContainer
            className="mt-4 border border-gray-200"
            center={position}
            style={{ height: 400, position: "relative" }}
            zoom={18}
          >
            <ChangeView markers={allData} current={current} />
            <div
              className="m-2 justify-end"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 9,
              }}
            >
              <button
                onClick={() => setUseSatellite(!useSatellite)}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                {useSatellite
                  ? t("common.changeToStreet", "Change to Street")
                  : t("common.changeToSatellite", "Change to Satellite")}
              </button>
            </div>
            {useSatellite ? (
              <ReactLeafletGoogleLayer
                apiKey={API_KEY}
                type={"satellite"}
                minZoom={3}
                maxZoom={23}
              />
            ) : (
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                minZoom={3}
                maxZoom={23}
              />
            )}

            {allData.map((f, index) => (
              <CustomMarker
                key={index}
                id={index}
                active={index === current}
                defaultColor={defaultColor}
                f={f}
                setCurrent={setCurrent}
              />
            ))}
            {showLine && (
              <Polyline pathOptions={pathOptions} positions={polyline} />
            )}
            <ImageGeolocationMarkers
              currentData={currentData}
              pathOptions={pathOptions}
              showLine={showLine}
            />
          </MapContainer>
        </div>
        {currentData && (
          <React.Fragment>
            <div className="mt-4 flex flex-wrap items-center justify-between px-4 sm:px-0">
              <div className="-mt-px flex w-0 flex-1"></div>
              <nav
                className="isolate inline-flex space-x-2 sm:space-x-4 rounded-md"
                aria-label="Pagination"
              >
                <div>
                  <button
                    type="button"
                    onClick={previous}
                    disabled={
                      current === 0 ||
                      isSubmitting ||
                      (showRemaining && remainingListIndex === 0)
                    }
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Previous</span>

                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex justify-center">
                  <div
                    className={`relative inline-flex items-center border border-gray-300 rounded-l-md bg-white px-4 py-2 text-sm font-medium text-gray-500 focus:z-20 ${
                      canReview || "rounded-r-md"
                    } ${isSubmitting && "opacity-50"}`}
                  >
                    <span className="sm:hidden">#</span>
                    <span className="hidden sm:inline-block">Record</span>
                    &nbsp;
                    {current + 1} / {allData.length}
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={next}
                    disabled={
                      isEnd ||
                      isSubmitting ||
                      (showRemaining &&
                        remainingListIndex === remainingList.length - 1)
                    }
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </nav>
              <div className="-mt-px flex flex-1 justify-end">
                {/* <div className="flex h-5 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={autoNext}
                    onChange={handleAutoNextCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Auto-next
                  </label>
                </div> */}
                <div className="flex h-5 items-center">
                  <input
                    id="show-remaining"
                    aria-describedby="comments-description"
                    name="show-remaining"
                    type="checkbox"
                    checked={showRemaining}
                    onChange={handleShowRemainingCheckBox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="show-remaining"
                    className="font-medium text-gray-700"
                  >
                    Only show unanswered questions
                  </label>
                </div>
                <div className="ml-3 flex h-5 items-center">
                  <input
                    data-testid="show-line"
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={showLine}
                    onChange={handleShowLineCheckbox}
                    className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <div className="ml-1 text-xs">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  >
                    Show line
                  </label>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {currentData && (
          <ReviewQuestions
            data={currentData}
            showAlert={showAlert}
            t={t}
            allData={allData}
            current={current}
            remainingQuestions={remainingQuestions}
            remainingList={remainingList}
            setRemainingList={setRemainingList}
          />
        )}
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(ReviewDetail);
