import {
  CalendarIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useUser } from "../../user/utils/user";

import { get, post, unwrapError } from "../../common/utils/api";
import Map from "../components/Map";
import Palette from "../components/Palette";
import { extractLabels } from "../utils/map";

function LandcoverReviewSingle({ showAlert, t }) {
  const mapStatic = useRef(null);
  const mapInteractive = useRef(null);
  const categoryRef = useRef();

  const navigate = useNavigate();

  const { user } = useUser();
  const canReview = user.user.is_supervisor;

  let [submission, setSubmission] = useState();

  const [searchParams] = useSearchParams();
  const submissionSelected = searchParams.get("submission_id");

  const [isSubmitting, setSubmitting] = useState(false);

  const getData = useCallback(async () => {
    get(`/landcover/review/single/?submission_id=${submissionSelected}`).then(
      (response) => {
        const submission = response.data;
        submission.task.grid = {
          type: "FeatureCollection",
          features: submission.data.map((d) => {
            return d.feature;
          }),
        };
        submission.initialLabels = submission.data.map((d) => d.label);
        setSubmission(submission);
      },
    );
  }, [submissionSelected, user]);

  useEffect(() => {
    getData();
  }, [getData]);

  function submit() {
    setSubmitting(true);

    const payload = {
      submission: submission.id,
      data: extractLabels(mapInteractive, submission.task.grid.features),
    };
    post("/landcover/review/submit", payload)
      .then(function (response) {
        showAlert({
          type: "success",
          message: t("common.successfullySubmitted", "Successfully submitted"),
        });
        navigate("/landcover/review/");
      })
      .catch(function (error) {
        showAlert({ type: "error", message: unwrapError(error) });
      })
      .finally(() => setSubmitting(false));
  }

  return user && submission ? (
    <React.Fragment>
      <div className="container mx-auto px-4 lg:px-8 py-2 my-2 ">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
          <div className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
            {/*User block*/}
            <div className="ml-4 mt-2 space-y-2">
              <div className="pb-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  Land Cover Submission
                </h1>
              </div>
              <div className="flex">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {new Date(submission?.created_at).toLocaleString()}
                </span>
              </div>
              <div className="flex">
                <UserIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {submission.user_name}
                </span>
              </div>
              <div className="flex">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-500">
                  {submission.user_email}
                </span>
              </div>
            </div>
            <span>Review the submission and make any corrections</span>
            <div className="ml-4 mt-2 flex-shrink-0">
              <div className="flex items-baseline">
                <button
                  type="button"
                  onClick={submit}
                  className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-4 mt-2"
                >
                  Submit Review
                </button>
              </div>
            </div>
          </div>
        </div>

        {submission && (
          <div className="mt-6 flex flex-row">
            <div className="flex-grow space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-center font-medium text-lg text-gray-700">
                    Reference
                  </div>
                  <Map
                    map={mapStatic}
                    interactive={false}
                    task={submission.task}
                    categoryRef={categoryRef}
                  />
                </div>
                <div>
                  <div className="text-center font-medium text-lg text-gray-700">
                    Target
                  </div>
                  <Map
                    map={mapInteractive}
                    interactive={true}
                    task={submission.task}
                    categoryRef={categoryRef}
                    initialLabels={submission.initialLabels}
                  />
                </div>
              </div>
            </div>

            <div className="mt-0 ml-6 flex-shrink-0 flex-grow-0">
              <Palette categoryRef={categoryRef} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  ) : null;
}
export default withTranslation()(LandcoverReviewSingle);
