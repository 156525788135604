import i18n from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div className="mt-8 text-center text-gray-900">
            Something went wrong. Please go to the{" "}
            <Link to="/debug">
              <p className="text-cyan-700">Advanced Page</p>
            </Link>
            and send us the current information.
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const getItemFromStorage = (key) => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const setItemInStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const removeItemFromStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const setLanguage = (language) => {
  if (language) {
    window.localStorage.defaultLanguage = language;
    i18n.changeLanguage(language);
  }
};

export const setStandalone = (standalone) => {
  if (standalone) {
    window.localStorage.standalone = true;
  }
};
export const getStandalone = () => window.localStorage.standalone;

// provide comparator function
export const comparator = (filterLocalDateAtMidnight, cellValue) => {
  var dateAsString = cellValue;
  if (dateAsString == null) {
    return 0;
  }

  // We create a Date object for comparison against the filter date
  var cellDate = new Date(dateAsString);
  cellDate.setHours(0, 0, 0, 0);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

// Expect string to look like 2011-12-31. We seem to need this format for the
// date input to work
export const formatDate = (date, timezone) => {
  let year = date.toLocaleString(undefined, {
    year: "numeric",
    timeZone: timezone,
  });
  let month = date.toLocaleString(undefined, {
    month: "2-digit",
    timeZone: timezone,
  });
  let day = date.toLocaleString(undefined, {
    day: "2-digit",
    timeZone: timezone,
  });
  return year + "-" + month + "-" + day;
};
export const formatTime = (date, timezone) => {
  return date.toLocaleTimeString(undefined, { timeZone: timezone });
};
export const formatDateTime = (params) => {
  return new Date(params.value).toLocaleString();
};

export const getImageUrl = (image) => {
  const isImageObject = (img) => {
    return typeof img === "object" && img.image ? img.image : img;
  };
  const MEDIAFILES_URL =
    process.env.REACT_APP_MEDIAFILES_URL || "http://localhost:8000/media";
  return MEDIAFILES_URL + "/" + isImageObject(image);
};

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export function getStandardizedQuestionCode(questionCode) {
  return questionCode % 1000;
}

export const calculatePercentage = (n, d) => {
  return d === 0 ? 0 : (n / d) * 100;
};
