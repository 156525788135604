import { BACKEND_URL } from "../common/utils/api";

const makeHeaders = (user, t) => {
  const header = [];

  header.push({
    to: "current",
    title: t("user.accountDetails", "Account Details"),
    desc: t(
      "user.viewAndChangeAccountInformation",
      "View and change account information.",
    ),
  });

  if (user?.user?.is_superuser) {
    header.push({
      to: `${BACKEND_URL}/admin/`,
      external: true,
      title: t("user.admin", "Admin"),
      desc: t("user.manageUsers", "Manage users etc."),
    });
  }

  return header;
};
export default makeHeaders;
