import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Home({ showAlert, t }) {
  return (
    <Link to="/delineation/task">
      <button
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 w-full sm:w-auto justify-center"
      >
        <RectangleGroupIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        {t("common.startLandcover", "Start Marking Land Cover")}
      </button>
    </Link>
  );
}
export default withTranslation()(Home);
